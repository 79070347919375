import React, { useState } from 'react';
import './RichTextEditor.css';
import UserSelectSkillDescriptors from './UserSelectSkillDescriptors';
import UserSelectSkills from './UserSelectSkills';
import UserSkillRTE from './UserSkillRTE';

const UserSkillManager = () => {
  const [skillDescriptor, setSkillDescriptor] = useState(null);
  const [skill, setSkill] = useState(null);
  
  const reset = () => {
    console.log("{UserSkillManager} RESET")
    setSkillDescriptor(null); // Set content in the parent state, which will hide UserSkillRTE
  };

  const handleContentUpdate = (newSkillDescriptor) => {
    console.log ("{UserSkillManager} handleContentUpdate: ", newSkillDescriptor);
    if (newSkillDescriptor == true) {
      setSkillDescriptor(null);
    }
    console.log("{UserSkillManager} Updated content from UserSelectSkillDescriptors child: ", newSkillDescriptor);
    console.log("{UserSkillManager} Current Selected Skill ", skill);
    setSkillDescriptor(newSkillDescriptor); // Set content in the parent state, which will update UserSelectSkillDescriptors and hide UserSkillRTE
  };

  return (
    <div className="editor-wrapper">
      <h1>Welcome to the Skills Log!</h1>
      <div className="skills-container">
        <div className="select-skill">
          <UserSelectSkills setSkill={setSkill} reset={reset} />
        </div>
      </div>

      {/* Conditionally render the skill descriptor list if skill is not null */}
      {skill ? (
        <div>
          <UserSelectSkillDescriptors skill={skill} onContentUpdate={handleContentUpdate} reset={reset}/>
        </div>
      ) : (
        <p>Please select a skill to start logging!</p>
      )}

      {/* Conditionally render the CKEditor container if skillDescriptor is not null */}
      {skillDescriptor ? (
        <div className="editor-container">
          <UserSkillRTE skill={skill} userSkillContent={skillDescriptor} /> {/* Pass content here */}
        </div>
      ) : (
        <p></p>
      )}
    </div>
  );
};

export default UserSkillManager;
