import { useState, useRef } from "react";
import React, { useContext, useEffect } from "react";
import { UserContext, DashboardContext } from "../../provider";
import { links } from "./navbar_data";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import { retrieveDash } from "../../api/dashboard";
import { headers } from "../../api/user";
import { useOrgLinks } from "../../action/dashboardActions";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { AppBar, Box, Toolbar, IconButton, Tabs, Tab, Avatar } from "@mui/material";
import NavbarImage from "./NavbarImage";
import { ProductEndpoints } from "../../api/Endpoints";
import { sendEndpointRequestNoAuth } from "../../iop/iop";

const Navbar = () => {
  const [value, setValue] = useState();
  const [showLinks, setShowLinks] = useState(false);
  const linksContainerRef = useRef(null);
  const linksRef = useRef(null);
  const { user } = useContext(UserContext);
  const { board, updateBoard } = useContext(DashboardContext);
  const { exeLogout } = useContext(UserContext);
  const { handleExecuteLink } = useOrgLinks();
  const [anchorEl, setAnchorEl] = useState(null);
  const [buildVersion, setBuildVersion] = useState("?");

  const toggle = () => {
    setShowLinks(!showLinks);
  };

  const linkStyles = {
    height: showLinks
      ? `${linksRef.current.getBoundingClientRect().height}px`
      : "0px",
  };

  useEffect(() => {
    if (!board || Object.values(board._links).length === 0) {
      loadDashboard();
    }
  }, [board, updateBoard]);

  const loadDashboard = async () => {

    /* get the build version for API */
    let response = await sendEndpointRequestNoAuth(ProductEndpoints.endpoints.getVersionBuildString, null, null)
    if (response.success) {
      setBuildVersion(response.data);
    }

    try {
      const data = await retrieveDash({
        headers: headers(),
      });
      updateBoard(data);
    } catch (error) {
      console.error("Failed to load dashboard:", error);
    }
  };

  const handleLogout = () => {
    exeLogout();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: "white" }}>
      <Box displayPrint="none">
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          {/* Logo */}
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="logo"
          >
            <NavbarImage />
          </IconButton>

          {/* Main Links (big screen) */}
          <div className="navbarTabs">
            <Tabs
              indicatorColor="secondary"
              textColor="inherit"
              value={value}
              onChange={(e, value) => setValue(value)}
            >
              {links.map((link) => {
                const { id, url, text, roles } = link;
                return (
                  JSON.stringify(roles).includes(user?.roles) && (
                    <Tab
                      label={text}
                      key={id}
                      href={url}
                      sx={{
                        color: "black",
                        fontWeight: "bold",
                        "&:hover": {
                          color: "lightgray",
                        },
                      }}
                    />
                  )
                );
              })}
            </Tabs>
          </div>

          {/* Hamburger (small screen) */}
          <button className="nav-toggle" onClick={toggle}>
            <MenuIcon />
          </button>


          {/* Settings / Profile Picture */}
          <div style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            {/* Settings Icon */}
            <SettingsIcon
              sx={{
                color: "black",
                cursor: "pointer",
                "&:hover": {
                  color: "lightgray",
                },
              }}
              onClick={handleClick}
            />
            {/* Profile Picture */}
            {/* <Avatar
              alt={user.name} // Optional: display the user's name as alt text
              src={user.profilePictureUrl}
              sx={{
                width: 40,
                height: 40,
                marginLeft: 2,
                cursor: "pointer",
              }}
            /> */}
          </div>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {board?._links &&
              Object.keys(board._links).map((linkKey) => {
                const link = board._links[linkKey];
                const buttonText = linkKey.replace(/\/\/\//g, " ");

                return (
                  <MenuItem
                    key="linkKey"
                    onClick={() => {
                      handleExecuteLink(link?.href);
                    }}
                  >
                    <a>{buttonText}</a>
                  </MenuItem>
                );
              })}
            <MenuItem key="logout" onClick={handleLogout}>
              <a>Logout</a>
            </MenuItem>
          </Menu>
        </Toolbar>

        {/* Links (dropdown on a small screen) */}
        <div
          className="navbarLinks-container"
          ref={linksContainerRef}
          style={linkStyles}
        >
          <ul className="navbarLinks" ref={linksRef}>
            {links.map((link) => {
              const { id, url, text, roles } = link;
              return (
                JSON.stringify(roles).includes(user?.roles) && (
                  <li key={id}>
                    <a href={url}>{text}</a>
                  </li>
                )
              );
            })}
          </ul>
        </div>
      </Box>
    </AppBar>
  );
};

export default Navbar;
