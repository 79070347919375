import { netSendRequest, TXHeaderType } from '../net/net.js';
import { ErrorType } from '../api/APIErrors.js';
import { TokenError } from './tkn.js';

/**
 * Application layer module for processing I/O requests.
 * @module IOP
 */

/**
 * Class representing a common response object.
 * This is the expected format for all responses from the API.
 */
export class GResponseCommonObject {

    /**
     * Construct a new GResponseCommonObject.
     * @param {success} whether or not the request was successful
     * @param {error} error object. will generally be null if success is true
     * @param {data} payload of the response
     */
    constructor(success, error, data) {
      this.success = success;
      this.error = error;
      this.data = data;
    }
  }

/**
 * Class representing an error response object.
 * This is the expected format for all error responses from the API.
 */
export class GResponseError {

    /**
     * Construct a new GResponseError.
     * @param {error} Error object as it is received from the API
     */
    constructor(error) {
      this.type = error.type;
      this.code = error.errorCode;
    }
  }

/**
 *
 * Send a request to the API with authentication.
 *
 * @param {Endpoint} endpoint   - The endpoint to send the request to.
 * @param {string} parms        - The parameters to send with the request.
 * @param {Object} data         - The data/body to send with the request (usually
 *                                JSON or something that can be serialized as JSON).
 * @returns
 */
export async function sendEndpointRequest(endpoint, parms=null, data, pathVars=null) {
    return await processEndpointRequest(TXHeaderType.AUTH, endpoint, parms, data, pathVars)
}

/**
 *
 * Send a request to the API with no authentication.
 *
 * @param {Endpoint} endpoint   - The endpoint to send the request to.
 * @param {string} parms        - The parameters to send with the request.
 * @param {Object} data         - The data/body to send with the request (usually
 *                                JSON or something that can be serialized as JSON).
 * @returns
 */
export async function sendEndpointRequestNoAuth(endpoint, parms=null, data, pathVars=null) {
  return await processEndpointRequest(TXHeaderType.NO_AUTH, endpoint, parms, data, pathVars)
}

/**
 *
 * @param {Endpoint} endpoint
 * @param {string} parms
 * @param {Object} data
 * @returns
 */
export async function sendEndpointRequestFile(endpoint, parms=null, data, pathVars=null) {
  return await processEndpointRequest(TXHeaderType.FILE, endpoint, parms, data, pathVars)
}

/**
 * Do I/O processing for a request to the API.
 */
async function processEndpointRequest(auth = true, endpoint, parms, data, pathVars) {
    let response = null

    /* Call to the network layer to send the request */
    try{
        response = await netSendRequest(auth, endpoint, parms, data, pathVars)

    /* Catch all erorrs thrown from the NET layer and parse/process them for the
     * eventual user code. 
     */
    } catch(error) {
        /* If this is a token error thrown at the client level return an
         * an unauth error.
         */
        if (error instanceof TokenError) {
            return new GResponseCommonObject(false, new GResponseError({type: ErrorType.ERR_USER_NO_AUTH,
                                                                        code: ErrorType.ERR_USER_NO_AUTH.code}),
                                                                        null)
        }

        /* */
        let response = error.response;

        /*
         * Nothing came back from the server or unable to interpret the response so
         * we either lost connection or effectly lost connection.
         */
        if (!response?.data?.success === null || !response?.data?.error) {
            return new GResponseCommonObject(false, new GResponseError({type: ErrorType.ERR_LOST_CONNECTION,
                                                                        code: ErrorType.ERR_LOST_CONNECTION.code}),
                                                                        null)
        }


        /* Load fields into the common data object and return to sender */
        let success = response.data.success;
        let apiError = new GResponseError(response.data.error);

        return new GResponseCommonObject(success, apiError, response.data.data)
    }

    /* If response doesn't exist then fabricate one. We have to assume
     * failure since network layer should always return response for
     * a valid request. 
     */
    if(!response)  {
      response = GResponseCommonObject(false, new GResponseError({type: ErrorType.ERR_UNKNOWN,
                                                                 code: ErrorType.ERR_UNKNOWN.code}),
                                                                 null);
    }

    return response;
}

/**
 * Determines if the response is a GResponse.
 * @param {Object} response - The response object.
 * @returns {boolean} True if the response is a GResponse, otherwise false.
 */
export function isGResponse(response) {
  /* If the input is valid,
   * The data, error and success properties are present,
   * The data property is an array,
   * The data array is not empty,
   * The error property and success properties exist in the data object
    return TRUE */
  return response
      && response.data
      && response.data.data
      && response.data.error   !== undefined
      && response.data.success !== undefined
}