import React, { createContext, useContext, useEffect, useState } from "react";
import { getCurrentUser, login } from "../api/user";
import { Loading } from "../components";
import { useNavigate } from "react-router-dom";

export const UserContext = createContext({});

export default function AppProvider({ children }) {
  const [user, setUser] = useState(undefined);
  const [debug, setDebug] = useState(true);
  const [errors, setErrors] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const loadUser = async () => {
    try {
      await getCurrentUser().then((data) => setUser(data));
    } catch (ex) {
      localStorage.clear();
    }
    setLoading(false);
  };

  useEffect(() => {
    loadUser();
  }, []);

  const handleLogin = (username, password) => {
    login(username, password)
      .then((response) => {
        console.log("Login successful:", response);
        getCurrentUser().then((data) => {
          setUser(data);
          setErrors(null);
          navigate(`/profile-landing/${data.email}`);
        });
      })
      .catch((e) => {
        setErrors({
          error: "Login failed. Check username and password and try again.",
        });
        setUser(null);
      });
  };

  const exeLogout = () => {
    localStorage.clear();
    setUser(null);
    setErrors(null);
    navigate("/");
  };

  return (
    <UserContext.Provider
      value={{
        user,
        updateUser: (user) => setUser(user),
        toggleDebug: () => setDebug(!debug),
        debug,
        setErrors,
        handleLogin,
        exeLogout,
        errors,
        navigate,
      }}
    >
      {loading && <Loading />}
      {!loading && children}
    </UserContext.Provider>
  );
}

export const useProvider = () => useContext(UserContext);
