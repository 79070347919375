import React, { useState, useContext, useEffect } from "react";
import { UserContext, DashboardContext } from "../../provider";
import { useLocation } from "react-router-dom";
import ErrorSection from "../utils/ErrorsSection";
import axios from "axios";
import { registerNewAdminAccount } from "../../api/user";
import { config } from "../../Constants";
import { CssBaseline, Typography } from "@mui/material";
import useStyles from "../../styles/styles";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/system/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import { toast } from "react-toastify";
import { ErrorType } from "../../api/APIErrors";
import { fieldErrorHandlerCommon, FieldErrorMapEntry } from "../../api/validation";
import { containsObjectsOfType } from "../../utl/typeUtl";
import { errorMap } from "./Registration";

const CreateAdmin = () => {
  const classes = useStyles();
  const { state } = useLocation();
  const { url } = state;

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [orgName, setSelectedOrg] = useState("");
  const [organizations, setOrganizations] = useState([]);
  const { setErrors, errors, navigate } = useContext(UserContext);
  const { loadAdmins } = useContext(DashboardContext);

  const handleRegisterNewAdmin = async (
    username,
    password,
    firstName,
    lastName,
    orgName,
    url
  ) => {
    setErrors(null);
    if (username == "" || password == "" || firstName == "" || lastName == "" || orgName == "") {
      setErrors("All fields are required.");
      return;
    }
    let response = await registerNewAdminAccount(
      username,
      password,
      firstName,
      lastName,
      orgName,
    )

    /* Upon success navigate the user to the admins page */
    if(response.success) {
      toast.success("Admin account created successfully. " + username);
      loadAdmins();
      navigate("/admins");

      return;
    } else {
      fieldErrorHandlerCommon(response, errorMap);
    }
  
    /* Otherwise handle API error cases */
    switch(response.error.code) {
      case ErrorType.ERR_DATABASE_DUPLICATE_ENTITY:
        toast.error("It looks like this admin already exists. At this time, admins can only oversee a single organization.");

      /* All other cases should be handled, post a message so we can
         handle update this and handle this case.
      */
      default:
        toast.error("An unknown error occurred. Please reach out to dev team for assistance.");
        break;
    }
  };

  useEffect(() => {
    const fetchOrganizations = async () => {
      const headers = {
        Accept: "*/*",
        "Content-Type": "application/json",
      };

      try {
        const response = await axios.get(
          `${config.url.API_BASE_URL}/api/registration/organizations`,
          { headers }
        );
        setOrganizations(response.data);
      } catch (err) {
      }
    };

    fetchOrganizations();
  }, []);

  const submitRegister = () => {
    handleRegisterNewAdmin(
      username,
      password,
      firstName,
      lastName,
      orgName,
      url
    );
  };

  return (
    <>
      <CssBaseline />
      <div className={classes.InputFormFlex}>
        <div>
          <Typography variant="h3" className={classes.FormCaption}>
            Create New Admin Account
          </Typography>
          <Grid container spacing={3} maxWidth={700}>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                id="email"
                name="email"
                label="Email"
                value={username}
                fullWidth
                variant="standard"
                onChange={(e) => setUsername(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                id="password"
                name="password"
                type="password"
                label="Password"
                value={password}
                fullWidth
                variant="standard"
                onChange={(e) => setPassword(e.target.value)}
                helperText="Password must be 8-30 characters, include 1 uppercase, 1 digit, 1 special character, no 3+ consecutive numbers, letters, or QWERTY characters, and no spaces."
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                id="firstName"
                name="firstName"
                label="First Name"
                value={firstName}
                fullWidth
                variant="standard"
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                id="lastName"
                name="lastName"
                label="Last Name"
                value={lastName}
                fullWidth
                variant="standard"
                onChange={(e) => setLastName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Select
                id="orgSelect"
                value={orgName}
                label="orgSelect"
                autoWidth
                displayEmpty
                input={<OutlinedInput />}
                onChange={(e) => setSelectedOrg(e.target.value)}
              >
                <MenuItem disabled value="">
                  <em>Select an Organization</em>
                </MenuItem>
                {organizations.map((org, index) => (
                  <MenuItem key={index} value={org}>
                    {org}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box sx={{ width: "300px" }}>
              <Button
                className={classes.button}
                onClick={submitRegister}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Register
              </Button>
            </Box>
          </Grid>

          <Grid container direction="column" alignItems="center">
            <Box sx={{ maxWidth: "550px" }}>
              <div>
                {errors && <ErrorSection errors={errors}></ErrorSection>}
              </div>
            </Box>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default CreateAdmin;
