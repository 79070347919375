import React, { useState, useEffect, useContext } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { config } from '../../Constants';
import axios from 'axios';
import { UserContext } from "../../provider";
import { sendEndpointRequest } from "../../iop/iop";
import { SkillEndpoints } from "../../api/Endpoints";


const UserSkillRTE = ({ skill, userSkillContent }) => {
    const { user } = useContext(UserContext);
    const [content, setContent] = useState('');
    const [uploadedImages, setUploadedImages] = useState([]);
    const [skillDescriptor, setSkillDescriptor] = useState(null);
    const [publicallyViewable, setPublicallyViewable] = useState(false);


    const getToken = () => {
        const token = localStorage.getItem('token');
        return token ? token.toString() : '';
    };

    const handleSaveContent = async () => {
        try {
            
            console.log("{UserSkillRTE} handleSaveContent():", user.email, skill.title, skillDescriptor,content);

            /* Make save request */
            const dto = { content: content };
            const pathVars = {
                "user-name": user.email,
                "skill-title": skill.title,
                "evidence-title": skillDescriptor
            };
            const response = sendEndpointRequest(SkillEndpoints.endpoints.saveUserSkillContent, null, dto, pathVars);

            /* Handle any API errors */
            if(!response.data?.succcess) {
                throw new Error(`Failed to save user skill content: ERROR: ${response.error?.code || 'Unknown error'}`);
            }

            console.log("Save User Skill Content Response: ", response);
        } catch (error) {
            console.error("Error in Save Skill Content (User): ", error);
            alert("Error Saving Skill Content");
        }
    };    

    const handleChange = (event, editor) => {
        const data = editor.getData();
        setContent(data);

        // Logic to determine deleted images
        detectDeletedImages(data);
    };

    const uploadImage = async (file) => {
        const formData = new FormData();
        formData.append('image', file);

        const response = await fetch(`${config.url.API_BASE_URL}/api/skill/upload-image?skill-id=${skill.skillId}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
            body: formData,
        });

        const result = await response.json();
        // Add the image URL to the uploadedImages state
        setUploadedImages((prev) => [...prev, result.data]);
        console.log("Uploaded Image: ", result);
        return result;
    };

    const deleteImageFromServer = async (imageUrl) => {
        // await fetch(`http://localhost:8080/api/skill/delete-image`, {
        //     method: 'DELETE',
        //     headers: {
        //         Authorization: `Bearer ${getToken()}`,
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify({ imageUrl }),
        // });
        console.log("TODO - DELETE IMAGE FROM SERVER: ", imageUrl);
    };

    const detectDeletedImages = (newContent) => {
        // Extract current image URLs from the content
        const currentImageUrls = Array.from(new DOMParser().parseFromString(newContent, 'text/html').querySelectorAll('img')).map(img => img.src);

        // Determine which images have been deleted
        const deletedImages = uploadedImages.filter(url => !currentImageUrls.includes(url));

        // If there are deleted images, remove them from the server
        deletedImages.forEach(async (url) => {
            await deleteImageFromServer(url);
        });

        // Update uploadedImages to reflect the current state
        setUploadedImages(currentImageUrls);
    };

    class MyUploadAdapter {
        constructor(loader) {
            this.loader = loader;
        }

        upload() {
            return this.loader.file.then((file) => {
                return new Promise((resolve, reject) => {
                    uploadImage(file)
                        .then((result) => {
                            resolve({
                                default: result.data,
                            });
                        })
                        .catch((error) => {
                            console.log("Error in Upload RTE Image (User): ", error);
                            reject(error);
                        });
                });
            });
        }

        abort() {}
    }

    function CustomUploadAdapterPlugin(editor) {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return new MyUploadAdapter(loader);
        };
    }

    const fetchContent = async () => {
        console.log("contentTitle: ", userSkillContent);
        try {
            const response = await axios.get(
                `${config.url.API_BASE_URL}/api/skill/${user.email}/${skill.title}/${userSkillContent}`, 
                // `${config.url.API_BASE_URL}/api/skill/${encodeURIComponent(user.email)}/${encodeURIComponent(skill.title)}/${encodeURIComponent(userSkillContent.contentTitle)}`, 
                {
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
            );
            console.log("RECIEVED NEW USER SKILL CONTEXT: ", response);

            setContent(response?.data?.txtContent ?? '');
            setSkillDescriptor(response?.data?.contentTitle);
            setPublicallyViewable(response?.data?.publiclyViewable);
            
        } catch (error) {
            console.error("Error fetching skill content: ", error);
        }
    };

    useEffect(() => {
        fetchContent(); // Fetch content when component mounts
    }, [skill.skillId, userSkillContent]);

    return (
        <div className="editor-container">
            <div className="header-container">
            <h4>{skill?.title ? skill.title : 'undefined'}</h4>
            <h4>{skillDescriptor ? skillDescriptor : 'undefined'}</h4>
            <h4>{publicallyViewable === true ? 'Public' : publicallyViewable === false ? 'Private' : 'undefined'}</h4>
            <button className="right-button" onClick={handleSaveContent}>Save Content</button>

            </div>
            <CKEditor
                editor={ClassicEditor}
                data={content}
                onChange={handleChange}
                config={{
                    extraPlugins: [CustomUploadAdapterPlugin],
                    toolbar: [
                        'heading', '|',
                        'bold', 'italic', 'underline', 'strikethrough', '|',
                        'link', 'bulletedList', 'numberedList', '|',
                        'alignment', 'fontSize', 'fontColor', 'fontBackgroundColor', '|',
                        'blockQuote', 'insertTable', 'horizontalLine', '|', 'undo', 'redo', 'removeFormat', 'imageUpload'
                    ],
                }}
            />
        </div>
    );
    
    
};

export default UserSkillRTE;
