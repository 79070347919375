import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { config } from '../../Constants';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const SkillResource = () => {
  const { skillTitle } = useParams();
  const [htmlContent, setHtmlContent] = useState('');
  const [loading, setLoading] = useState(true);

  const fetchSkillData = async (title) => {
    try {
      const response = await axios.get(
        `${config.url.API_BASE_URL}/api/skill/${title}`
      );
      console.log('{SkillResource} Success fetching skill resource:', response);

      if (response.data.txtContent) {
        setHtmlContent(response.data.txtContent);
      }
    } catch (error) {
      console.error('{SkillResource} Error fetching skill data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSkillData(skillTitle);
  }, [skillTitle]);

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <CKEditor
          editor={ClassicEditor}
          data={htmlContent}
          disabled={true}
          config={{
            toolbar: [], /* Read only */
          }}
        />
      )}
    </div>
  );
};

export default SkillResource;
