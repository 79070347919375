import React, { useState } from 'react';
import './RichTextEditor.css';
import CreateSkill from './OwnerCreateSkill';
import OwnerSelectSkills from './OwnerSelectSkills';
import OwnerRTE from './OwnerRTE';

const OwnerSkillManager = () => {
  const [content, setContent] = useState('');
  const [skill, setSkill] = useState(null);
  const [createNew, setCreateNew] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0); // Key to trigger refresh

  // Function to trigger refresh
  const triggerRefresh = () => {
    setRefreshKey((prevKey) => prevKey + 1);
    setSkill(null);
  };

  return (
    <div className="editor-wrapper">
      <h1>Welcome to the Skill Manager!</h1>
      <div className="skills-container">
        <div className="select-skill">
          {/* Pass refreshKey to OwnerSelectSkills to trigger re-fetch */}
          <OwnerSelectSkills 
            setSkill={setSkill} 
            refreshKey={refreshKey} 
          />
        </div>
          <div className="create-skill">
            <CreateSkill
              setSkill={(newSkill) => {
                setSkill(newSkill);
                triggerRefresh(); // Trigger refresh after creating a new skill
              }}
            />
          </div>
      </div>

      {/* Conditionally render the CKEditor container if skill is not null */}
      {skill ? (
        <div className="editor-container">
          <OwnerRTE skill={skill}/>
        </div>
      ) : (
        <p>Please create or select a skill to start editing.</p>
      )}
    </div>
  );
};

export default OwnerSkillManager;
