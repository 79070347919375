/**
 * !!! DO NOT EDIT THIS FILE DIRECTLY !!!
 *
 * This file is generated from the template file located in API repository at:
 * support/APIErrors.js.template and all updates to error codes should
 * be made via error_codes.py.
 *
 */

export const ErrorType = {
    ERR_NO_ERR: -2,             /* is it too late to make this 0? */
    ERR_LOST_CONNECTION: -1,
    ERR_UNKNOWN: 0,
    ERR_USER_NOT_FOUND: 100,
    ERR_USER_REGISTRATION_FAILED: 101,
    ERR_USER_NO_AUTH: 102,
    ERR_RELATION_NOT_ASSOCIATED: 900,
    ERR_RELATION_DUPLICATE_MEMBER: 901,
    ERR_DATABASE_ENTITY_NOT_FOUND: 300,
    ERR_DATABASE_DUPLICATE_ENTITY: 301,
    ERR_SERVER_INVALID_STATE: 400,
    ERR_SERVER_INTERNAL: 401,
    ERR_SERVER_IMPROPER_DATA_HANDLING: 402,
    ERR_GENERIC_ACCESS_DENIED: 500,
    ERR_GENERIC_BAD_REQUEST: 501,
    ERR_FILE_FILE_UPLOAD_FAILED: 200,
    ERR_FILE_FILE_SIZE_EXCEEDED: 201,
    ERR_FILE_UNSUPPORTED_FILE_TYPE: 202,
    ERR_FILE_FILE_FORMAT_INVALID: 203,
    ERR_INPUT_VALIDATION_INVALID_INPUT: 600,
    ERR_INPUT_VALIDATION_MISSING_REQUIRED_FIELD: 601,
    ERR_INPUT_VALIDATION_INVALID_EMAIL: 602,
    ERR_INPUT_VALIDATION_PAST_OR_PRESENT_DATE: 603,
    ERR_INPUT_VALIDATION_SIZE_EXCEEDED: 604,
    ERR_INPUT_VALIDATION_INVALID_DATE: 605,
    ERR_INPUT_VALIDATION_NOT_NULL: 606,
    ERR_INPUT_VALIDATION_PASSWORD_REQUIREMENTS_NOT_MET: 607,
    ERR_INPUT_VALIDATION_VALUE_NOT_IN_RANGE: 608,
    ERR_INPUT_VALIDATION_PHONE_NUMBER_FORMAT: 609,
    ERR_DATA_PARSING_UNRECOGNIZED_PROPERTY: 700,
    ERR_API_ENDPOINT_GONE: 800,
    ERR_API_ENDPOINT_UNIMPLEMENTED: 801,
    /* DEFINE ERROR CODES ABOVE */
  };