import React, { useState, useContext } from "react";
import { UserContext } from "../../provider/AppProvider";
import ErrorSection from "../utils/ErrorsSection";
import { registerNewAccount } from "../../api/user";
import { CssBaseline, Typography } from "@mui/material";
import useStyles from "../../styles/styles";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/system/Box";
import { ErrorType } from "../../api/APIErrors";
import EmailIcon from '@mui/icons-material/Email'; 
import { fieldErrorHandlerCommon, FieldErrorMapEntry } from "../../api/validation";
import { useNavigate } from "react-router-dom"; // Add navigation


/* Field violations error map */
export const errorMap = [
  new FieldErrorMapEntry("email", ErrorType.ERR_INPUT_VALIDATION_INVALID_EMAIL, "email", "Invalid email format."),
  new FieldErrorMapEntry("password", ErrorType.ERR_INPUT_VALIDATION_PASSWORD_REQUIREMENTS_NOT_MET, "password", "Password requirements not met."),
  new FieldErrorMapEntry("firstName", ErrorType.ERR_INPUT_VALIDATION_SIZE_EXCEEDED, "firstName", "First name must be less than 255 characters."),
  new FieldErrorMapEntry("lastName", ErrorType.ERR_INPUT_VALIDATION_SIZE_EXCEEDED, "lastName", "Last name must be less than 255 characters.")
]

const Register = () => {
  const classes = useStyles();
  const navigate = useNavigate(); // Navigation hook
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [orgName] = useState("");
  const { errors, setErrors } = useContext(UserContext);
  const [successHeader, setSuccess] = useState("");

  async function submitRegister() {
    if (username === "" || password === "" || firstName === "" || lastName === "") {
      setErrors("All fields are required.");
      return;
    }

    let response = await registerNewAccount(username, password, firstName, lastName, orgName);
    if (response.success) {
      setSuccess("Please verify email.");
    } else {
      handleErrors(response);
    }
  };

  function handleErrors(response) {
    if (!fieldErrorHandlerCommon(response, errorMap)) {

      handleRegistrationError(response.error);
    }
  }


  function handleRegistrationError(error) {
    if (!error) {
      setErrors("An unknown error occurred.");
    } else if (error.code === ErrorType.ERR_USER_REGISTRATION_FAILED) {
      setErrors("Failed to register account.");
    } else {
      setErrors("An unknown error occurred.");
    }
  }

  return (
    <>
      <CssBaseline />
      <div className={classes.InputFormFlex}>
        <div>
          {successHeader === "" && (
            <Typography variant="h3" style={{ paddingBottom: "40px" }}>
              Register Account
            </Typography>
          )}
          {successHeader !== "" && (
            <div style={{ boxShadow: "0 4px 8px rgba(0,0,0,0.1)", padding: "20px", borderRadius: "10px" }}>
              <h1>{successHeader}</h1>
              <div style={{ marginBottom: "40px", alignItems: "center" }}>
                <EmailIcon sx={{ fontSize: 60, marginTop: "20px" }} />
              </div>
              <h3>You're almost there! We are sending a verification email to</h3>
              <h2>{username}</h2>
              <p>It may take several minutes to receive. Simply click the link in the email <br />to verify once you have received it. Make sure to check your spam folder as well.</p>
              <p>Still can't find it? Reach out to your <bold style={{ color: "HighlightText" }}>Organization's Administrator</bold></p>
            </div>
          )}

          {successHeader === "" && (
            <Grid container spacing={3} maxWidth={700}>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  id="email"
                  name="email"
                  label="Email"
                  value={username}
                  fullWidth
                  variant="standard"
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  id="password"
                  name="password"
                  type="password"
                  label="Password"
                  value={password}
                  fullWidth
                  variant="standard"
                  onChange={(e) => setPassword(e.target.value)}
                  helperText="Password must be 8-30 characters, include 1 uppercase, 1 digit, 1 special character, no 3+ consecutive numbers, letters, or QWERTY characters, and no spaces."
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  id="firstName"
                  name="firstName"
                  label="First Name"
                  value={firstName}
                  fullWidth
                  variant="standard"
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  id="lastName"
                  name="lastName"
                  label="Last Name"
                  value={lastName}
                  fullWidth
                  variant="standard"
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Grid>
            </Grid>
          )}
          
          {successHeader === "" && (
            <Grid container direction="column" alignItems="center" justifyContent="center">
              <Box sx={{ width: "300px" }}>
                <Button
                  className={classes.button}
                  onClick={() => submitRegister()}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Register
                </Button>
              </Box>
            </Grid>
          )}

          {successHeader === "" && (
            <Grid container direction="column" alignItems="center" justifyContent="center">
              <Button
                onClick={() => navigate("/login")}
                sx={{ mt: 3, mb: 2, textTransform: "none" }}
              >
                Already have an account? Login
              </Button>
            </Grid>
          )}

          {successHeader === "" && (
            <Grid container direction="column" alignItems="center">
              <Box sx={{ maxWidth: "550px" }}>
                <div>
                  <ErrorSection errors={errors} />
                </div>
              </Box>
            </Grid>
          )}
        </div>
      </div>
    </>
  );
};

export default Register;
