import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { sendEndpointRequestNoAuth } from "../../../iop/iop";
import { SkillEndpoints } from "../../../api/Endpoints";
import CircularProgress from '@mui/material/CircularProgress';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const useStyles = makeStyles((theme) => ({
  centeredContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '200px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    padding: theme.spacing(3),
    boxShadow: theme.shadows[1],
  },
  loadingText: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
  },
  loadingIcon: {
    color: theme.palette.primary.main,
  },
  errorText: {
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2),
  },
  errorIcon: {
    color: theme.palette.error.main,
    fontSize: '2rem',
  },
  noDataContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '30vh',
  },
  noDataText: {
    color: theme.palette.text.secondary,
  },
  container: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  box: {
    backgroundImage: 'linear-gradient(to right, #e6e8e8, #d1d4d4)',
    borderRadius: '8px',
    padding: theme.spacing(2),
    boxShadow: theme.shadows[3],
    border: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    transition: 'background-color 0.3s ease, border 0.3s ease',
    "&:hover": {
      backgroundImage: 'linear-gradient(to left, #e7b3f5, #d7bdde)',
      border: `4px solid ${theme.palette.divider}`,
    },
  },

  header: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    textAlign: 'left',
    marginBottom: theme.spacing(1),
  },
  text: {
    fontSize: '1rem',
    textAlign: 'left',
    color: theme.palette.text.primary,
  },
}));

const UserQuickActionPane = ({ username }) => {
  const classes = useStyles();
  const [actionData, setActionData] = useState([]);

  const DisplayDataState = Object.freeze({
    VISIBLE: "visible",
    LOADING: "loading",
    NO_DATA: "no data",
    FAILED_FETCH: "failed fetch",
  });
  const [displayDataState, setDisplayDataState] = useState(DisplayDataState.LOADING);

  const fetchUserSkills = async () => {
    try {
      const pathVars = { "user-name": username };
      const response = await sendEndpointRequestNoAuth(SkillEndpoints.endpoints.getUserSkillList, null, null, pathVars);
      
      if (response.data && Array.isArray(response.data)) {
        if (response.data.length === 0) {
          setDisplayDataState(DisplayDataState.NO_DATA);
        } else {
          setActionData(response.data);
          setDisplayDataState(DisplayDataState.VISIBLE);
        }
      } else {
        setDisplayDataState(DisplayDataState.FAILED_FETCH);
      }
    } catch (error) {
      setDisplayDataState(DisplayDataState.FAILED_FETCH);
    }
  };

  useEffect(() => {
    if (username) {
      fetchUserSkills();
    }
  }, [username]);

  /* Loading display state */
  if (displayDataState === DisplayDataState.LOADING) {
    return (
      <Box className={classes.centeredContainer}>
        <Typography variant="h6" className={classes.loadingText}>
          Retrieving Skill Data...
        </Typography>
        <CircularProgress className={classes.loadingIcon} />
      </Box>
    );
  }

  /* Failed fetch display state */
  if (displayDataState === DisplayDataState.FAILED_FETCH) {
    return (
      <Box className={classes.centeredContainer}>
        <Typography variant="h6" className={classes.errorText}>
          Failed to fetch skill data. Please try again later.
        </Typography>
        <ErrorOutlineIcon className={classes.errorIcon} />
      </Box>
    );
  }

/* No data display state */
if (displayDataState === DisplayDataState.NO_DATA) {
  return (
    <div className={classes.noDataContainer}>
      <Typography variant="h4" className={classes.noDataText}>
        No Skills available for this user
      </Typography>
      <Typography variant="h6" className={classes.noDataText}>
       Come back soon to see what John Doe is working on
      </Typography>
    </div>
  );
}
  /* Success case display state */
  return (
    <Box className={classes.container}>
      {actionData.map((item, index) => (
        <Box key={index} className={classes.box}>
          <Typography variant="h6" className={classes.header}>
            {item.parentSkillTitle}
          </Typography>
          <Typography className={classes.text}>
            Badges Earned: 12
          </Typography>
          <Typography className={classes.text}>
            Endorsements: 3
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default UserQuickActionPane;
