import { GResponseCommonObject } from "../iop/iop";
import { containsObjectsOfType } from "../utl/typeUtl";
import { ErrorType } from "./APIErrors";

export class FieldViolation {
    constructor(errorCode, field){
        this.errorCode = errorCode;
        this.field = field;
    }
}

/**
 * Class representing a field error map entry.
 *
 * @param {string} field - The field that has an error.
 * @param {ErrorType} error - The error code indicated on the field.
 * @param {string} componentId - The id of the component that has the error.
 */
export class FieldErrorMapEntry {
    constructor(field, error, componentId, message){
        this.field = field;
        this.error = error;
        this.componentId = componentId;
        this.message = message;
    }

}

/**
 * Extract input Field Vaildations this is typically used when we expect
 * an invalid input error, which for some interfaces, especially those
 * involved in submitting forms, contain a report of why the input failed.
 *
 * @param {GResponseCommonObject} response 
 * @param {FieldViolation} outViolations 
 * @returns false if we cannot extract any field validations true if we can
 */
function extractValidationViolations(response, outViolations) {
    /* Initialize the output array */
    outViolations.out = [];

    /*  Input valildation
     *  Is the response an instance of GResponseCommonObject and does it contain the expected data?
     */
    if (!(response instanceof GResponseCommonObject) ||
        response.success || 
        !response.error || 
        response?.error?.code !== ErrorType.ERR_INPUT_VALIDATION_INVALID_INPUT || 
        !Array.isArray(response?.data)
    ) {
        return false;
    }


    /* Extract the violations */
    response.data.forEach(violation => {
        let errorCode = violation.error.errorCode;
        let field = violation.field;
        outViolations.out.push(new FieldViolation(errorCode, field));
    });

    return true;
}

/**
 * Common handler to extract and display Field Validation errors
 *
 * A Field Validation error is an error which occurs on a user form.
 * For example, this could happen if the user inputs an invalid email
 * format in a text field, or if they use too many characters in a
 * text box.
 *
 * One downside to this approach is that we depend upon the Genics API
 * to tell us when we have invalid input, instead of handling it locally
 * on the users PC. But, the upside is we don't have to duplicate logic.
 *
 * @param {GResponseCommonObject} response - System common response object.
 * @param {FieldErrorMapEntry[]} errorMap - List of field error mappings.
 *
 * @returns {boolean} True if we can display the field validation errors
 *                    and otherwise false.
 */
export function fieldErrorHandlerCommon(response, errorMap) {
        /* Display any field violation errors */
        let out = [];
        if( extractValidationViolations(response, out) ) {
            displayFieldViolations(out.out, errorMap);
            return true;
        } else {
            return false;
        }
}

/**
 *
 * Display the field violations to the user.
 *
 * @param {fieldViolations}
 *              fieldViolations violations of some rule e.g., max characters,
 *              exceeded invalid email, etc.)
 * @param {FieldErrorMapEntry}
 *              errorMappings mapping of UI element ids to error codes and their
 *              corresponding error messages, e.g., display "Invalid email" when
 *              a error on this field is ERR_INPUT_VALIDATION_INVALID_EMAIL
 *
 */
function displayFieldViolations(fieldViolations, errorMap) {
    /* Input validation */
    if( !( containsObjectsOfType( errorMap, FieldErrorMapEntry ) ) ) {
        return;
    }

    fieldViolations.forEach(violation => {
        const { errorCode, field } = violation;

        /* Display the error on any matching field error entries
         * in the error map.
         */
        displayErrors(errorMap, field, errorCode);
    });
}

function displayErrors(errorMap, field, errorCode) {
    errorMap.forEach((entry) => {

        /* Input validation */
        if( !( entry instanceof FieldErrorMapEntry ) ){
            return;
        }

        const { field: mapField, error, componentId, message } = entry;

        /* Display the error message if the error code and field match */
        if ( ( error === errorCode ) && ( field === mapField ) ) {
    
            const textField = document.getElementById(componentId);
    
            /* Only display one error message at a time */
            if (textField === null || textField.genicsValidationFlag) {
                return;
            }

            /* Update the UI of the component */

            textField.style.borderColor = "red";
            textField.genicsValidationFlag = true;

            /* Create a div element for the error message */
            const errorContainer = document.createElement("div");
            errorContainer.className = "error-message";
            errorContainer.textContent = message;

            /* Apply CSS styles for red color and left justification */
            errorContainer.style.color = "red";
            errorContainer.style.textAlign = "left";

            /* Append the error message after the text field's parent container */
            textField.parentNode.parentNode.appendChild(errorContainer);

            /* Clear errors when focused */
            textField.addEventListener("focus", () => {
                errorContainer.remove();
                textField.style.borderColor = "";
                textField.genicsValidationFlag = false;
            });

            /* Only display the first error message */
            return;
        }
    });
}


