/**
 * !!! DO NOT EDIT THIS FILE DIRECTLY !!! 
 *
 * This file is generated by the api.py in the API repo. 
 * Any changes made to this file will be overwritten.
 * 
 */

import { EndpointType, Endpoint } from './APIManager';

export const ProductEndpoints = EndpointType.create('/api/product', {
	getVersionBuildString: Endpoint.create('/api/product/build', 'Get'),
});

export const AuthPasswordEndpoints = EndpointType.create('/api/authenticated-pass', {
	authResetPass: Endpoint.create('/api/authenticated-pass/', 'Post'),
});

export const UserSkillStatsDto = EndpointType.create('', {
});

export const OrganizationEndpoints = EndpointType.create('/api/organization', {
	getOrgsOfAnAdmin: Endpoint.create('/api/organization/admin-orgs', 'Get'),
	getOrgNamesForReg: Endpoint.create('/api/organization/organizations', 'Get'),
	userGetOrgByID: Endpoint.create('/api/organization/', 'Get'),
	createOrganization: Endpoint.create('/api/organization/create', 'Post'),
	createOrganizationWithConfigurations: Endpoint.create('/api/organization/create-org', 'Post'),
	getOrganization: Endpoint.create('/api/organization/detail/{orgId}', 'Get'),
	getAllOrganizations: Endpoint.create('/api/organization/all', 'Get'),
	addAdmin: Endpoint.create('/api/organization/addAdmin', 'Post'),
	removeAdmin: Endpoint.create('/api/organization/removeAdmin/{adminEmail}/{orgId}', 'Post'),
	addUser: Endpoint.create('/api/organization/add-user', 'Post'),
	removeUser: Endpoint.create('/api/organization/remove-user/{email}/{orgId}', 'Post'),
	edit: Endpoint.create('/api/organization/{orgName}/edit', 'Put'),
	softDeleteOrganization: Endpoint.create('/api/organization/delete-org/{orgId}', 'Post'),
	associateBadgeWithOrganization: Endpoint.create('/api/organization/associate-badge', 'Post'),
	removeBadgeFromOrganization: Endpoint.create('/api/organization/remove-badge', 'Post'),
	getAllBadgesForOrganization: Endpoint.create('/api/organization/organization-badges', 'Get'),
});

export const HealthEndpoints = EndpointType.create('/api/health', {
	getAPIHealth: Endpoint.create('/api/health/get-health', 'Get'),
});

export const OwnerEndpoints = EndpointType.create('/api/owner', {
	softDeleteUserByEmail: Endpoint.create('/api/owner/soft-delete-user', 'Post'),
	getAllAdminRole: Endpoint.create('/api/owner/get-all-role-admins', 'Get'),
	getAllUserRole: Endpoint.create('/api/owner/get-all-role-users', 'Get'),
	getAllDeletedUsers: Endpoint.create('/api/owner/deleted-users', 'Get'),
	getAllDeletedAdmins: Endpoint.create('/api/owner/deleted-admins', 'Get'),
	restoreAccount: Endpoint.create('/api/owner/restore-account', 'Post'),
	getAllUsersWithNoOrg: Endpoint.create('/api/owner/no-org-users', 'Get'),
	getAllAdminsWithNoOrg: Endpoint.create('/api/owner/no-org-admins', 'Get'),
	addToOrg: Endpoint.create('/api/owner/add-to-org', 'Post'),
	manuallyVerifyAccounts: Endpoint.create('/api/owner/manual-verification', 'Post'),
	getAllUnverifiedAccounts: Endpoint.create('/api/owner/all-unverified', 'Get'),
	uploadBulkUserJoinRequestList: Endpoint.create('/api/owner/upload-bulk-user-join-requests', 'Post'),
});

export const SkillEndpoints = EndpointType.create('/api/skill', {
	createUserSkill: Endpoint.create('/api/skill/create-user-skill', 'Post'),
	getAllPublicSkills: Endpoint.create('/api/skill/query', 'Get'),
	getAllPrivateSkills: Endpoint.create('/api/skill/get-skill-list-private', 'Get'),
	getAllSkills: Endpoint.create('/api/skill/get-skill-list-all', 'Get'),
	saveSkillContent: Endpoint.create('/api/skill/save-skill-content', 'Post'),
	createNewSkill: Endpoint.create('/api/skill/create-skill', 'Post'),
	updateSkillTitle: Endpoint.create('/api/skilledit-skill-title/{skill-title}', 'Post'),
	updateEvidenceContentTitle: Endpoint.create('/api/skilledit-content-title/{user-name}/{skill-title}/{content-title}', 'Post'),
	getSkillContext: Endpoint.create('/api/skill/{skill-title}', 'Get'),
	getUserSkillList: Endpoint.create('/api/skill/get-user-skill-list/{user-name}', 'Get'),
	getUserSkillStats: Endpoint.create('/api/skill/get-user-skill-stats', 'Get'),
	getEvidenceDescriptorList: Endpoint.create('/api/skill/{user-name}/{skill-title}', 'Get'),
	getEvidenceContext: Endpoint.create('/api/skill/{user-name}/{skill-title}/{content-title}', 'Get'),
	createNewEvidence: Endpoint.create('/api/skill/create-new-evidence', 'Post'),
	saveUserSkillContent: Endpoint.create('/api/skill/update-evidence/{user-name}/{skill-title}/{evidence-title}', 'Put'),
	publishSkill: Endpoint.create('/api/skill/publish-skill', 'Put'),
	publishEvidenceContent: Endpoint.create('/api/skill/publish/{user-name}/{skill-title}/{content-title}', 'Put'),
	unpublishEvidenceContent: Endpoint.create('/api/skill/unpublish/{user-name}/{skill-title}/{content-title}', 'Put'),
	unpublishSkill: Endpoint.create('/api/skill/unpublish-skill', 'Put'),
	uploadSkillImage: Endpoint.create('/api/skill/upload-image', 'Post'),
	uploadUserSkillImage: Endpoint.create('/api/skill/upload-image-user-skill', 'Post'),
});

export const AuthEndpoints = EndpointType.create('/api/auth', {
	authenticate: Endpoint.create('/api/auth/authenticate', 'Post'),
});

export const RegistrationEndpoints = EndpointType.create('/api/registration', {
	createNewAccount: Endpoint.create('/api/registration/', 'Post'),
	confirmUser: Endpoint.create('/api/registration/confirmRegistration', 'Post'),
	resendRegistrationToken: Endpoint.create('/api/registration/resendRegistrationToken', 'Get'),
	createAdminAccount: Endpoint.create('/api/registration/createAdmin', 'Post'),
	getOrgNamesForReg: Endpoint.create('/api/registration/organizations', 'Get'),
});

export const BadgeEndpoints = EndpointType.create('/api/badge', {
	createNewBadge: Endpoint.create('/api/badge/upload', 'Post'),
	editBadgeIcon: Endpoint.create('/api/badge/edit-icon/{badgeId}', 'Put'),
	createUserBadge: Endpoint.create('/api/badge/createUserBadge', 'Post'),
	createNewBadgeNoFile: Endpoint.create('/api/badge/upload-no-file', 'Post'),
	getUsersUserBadges: Endpoint.create('/api/badge/user-badges', 'Get'),
});

export const DashEndpoints = EndpointType.create('/api/dashboard', {
	getDashBoard: Endpoint.create('/api/dashboard/get-dash', 'Get'),
});

export const EndorsementEndpoints = EndpointType.create('/api/endorsement', {
	requestEndorsement: Endpoint.create('/api/endorsement/request/{user-name}/{skill-title}/{content-title}', 'Post'),
	saveEndorsement: Endpoint.create('/api/endorsement/submit', 'Post'),
	getAllEndorsementsNotHidden: Endpoint.create('/api/endorsement/not-hidden', 'Get'),
	getEndorsementsReport: Endpoint.create('/api/endorsement/report', 'Get'),
	getAllEndorsementsHidden: Endpoint.create('/api/endorsement/hidden', 'Get'),
	getEndorsementByTokenId: Endpoint.create('/api/endorsement/by-token', 'Get'),
	hideEndorsement: Endpoint.create('/api/endorsement/hide', 'Post'),
	unhideEndorsement: Endpoint.create('/api/endorsement/unhide', 'Post'),
	deleteEndorsementById: Endpoint.create('/api/endorsement/delete', 'Delete'),
});

export const HealthCheckEndpoints = EndpointType.create('/api/health-check', {
	healthCheck: Endpoint.create('/api/health-check/', 'Get'),
});

export const LogoutEndpoints = EndpointType.create('/api/user', {
	logout: Endpoint.create('/api/user/logout', 'Get'),
});

export const PasswordResetEndpoints = EndpointType.create('/api/resetPassword', {
	resetPassword: Endpoint.create('/api/resetPassword', 'Post'),
	validateToken: Endpoint.create('/api/resetPassword/validateToken', 'Get'),
	showChangePasswordPage: Endpoint.create('/api/resetPassword/changePassword', 'Get'),
	savePassword: Endpoint.create('/api/resetPassword/savePassword', 'Post'),
});

export const UserEndpoints = EndpointType.create('/api/user', {
	getCurrentUserSessionId: Endpoint.create('/api/user/mysession', 'Get'),
	getCurrentUser: Endpoint.create('/api/user/me', 'Get'),
	getUserContext: Endpoint.create('/api/user/get-user-context', 'Get'),
	getUserByEmail: Endpoint.create('/api/user/user_id', 'Get'),
	getUserById: Endpoint.create('/api/user/id/{userId}', 'Get'),
	getAllUsers: Endpoint.create('/api/user/users/all', 'Get'),
	getUserDashboard: Endpoint.create('/api/user/dashboard', 'Get'),
	keepMySessionAlive: Endpoint.create('/api/user/keep-alive', 'Get'),
	uploadUserAvatar: Endpoint.create('/api/user/upload-avatar', 'Put'),
	getUserAvatar: Endpoint.create('/api/user/get-avatar', 'Get'),
	getUserSocialMediaLinks: Endpoint.create('/api/user/get-user-social-medias', 'Get'),
});

