import React, { useState, useContext, useEffect } from "react";
import { UserContext, DashboardContext } from "../../provider";
import { useLocation, Link } from "react-router-dom";
import ErrorSection from "../utils/ErrorsSection";
import { headers } from "../../api/user";
import axios from "axios";
import { CssBaseline, Typography, Box, Button, Paper } from "@mui/material";
import useStyles from "../../styles/styles";
import theme from "../../styles/theme";
import { ThemeProvider } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

const AllBadgeEndorsements = () => {
  const classes = useStyles();
  const { state } = useLocation();
  const { url } = state;
  const { eventTitle } = state;

  const { setErrors, errors, navigate } = useContext(UserContext);
  const [endorsements, setEndorsements] = useState([]);
  const [endorsementToDeleteFromPage, setEndorsementToDeleteFromPage] =
    useState("");
  const [selectedEndorsement, setSelectedEndorsement] = useState({});
  const [
    showDeleteEventConfirmationPopup,
    setShowDeleteEndorsementConfirmationPopup,
  ] = useState(false); // State to control the visibility of the confirmation popup

  const loadEndorsements = async () => {
    try {
      console.log("Link: ", url.href);
      const response = await axios.get(url.href, { headers: headers() });
      console.log("Load Endorsements: ", response);
      setEndorsements(response.data);
    } catch (err) {
      console.error("Load Endorsements Error: ", err);
    }
  };

  function formatDate(dateString) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZoneName: "short",
    };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  }

  const handleDeleteEndorsement = (link, endorsement) => {
    setEndorsementToDeleteFromPage(endorsement);
    setSelectedEndorsement({ link });
    setShowDeleteEndorsementConfirmationPopup(true);
  };

  const confirmDeleteEndorsement = async () => {
    try {
      // Execute the delete event link.href
      console.log("URL TO DELETE END: ", selectedEndorsement.link.href);
      const response = await axios.delete(selectedEndorsement.link.href, {
        headers: headers(),
      });
      // Reload the endorsements after deletion
      console.log(response);
      // loadAllEvents();
    } catch (error) {
      console.error("Error deleting event: ", error);
      setErrors(errors);
    } finally {
      // Close the confirmation popup
      setEndorsements((prevEndorsements) =>
        prevEndorsements.filter((item) => item !== endorsementToDeleteFromPage)
      );
      setShowDeleteEndorsementConfirmationPopup(false);
    }
  };

  const cancelDeleteEndorsement = () => {
    // Close the confirmation popup
    setEndorsementToDeleteFromPage(null);
    setShowDeleteEndorsementConfirmationPopup(false);
  };

  useEffect(() => {
    loadEndorsements();
  }, []);

  return (
    <>
      <CssBaseline />
      <Typography variant="h6" style={{ textAlign: "left" }}>
        <Link to={"/mybadges"} style={{ marginRight: "20px" }}>
          Back to Badges
        </Link>
      </Typography>

      <Typography variant="h3">Endorsements for:</Typography>
      <Typography variant="h4">{eventTitle}</Typography>
      {showDeleteEventConfirmationPopup && (
        <Paper
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 999,
            backgroundColor: "white",
          }}
        >
          <Box sx={{ m: 2 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", color: 'red' }}>
              Are you sure you want to delete this endorsement? We cannot
              recover this action!
            </Typography>
            <Button
              className={classes.button}
              variant="contained"
              sx={{ m: 1 }}
              onClick={confirmDeleteEndorsement}
            >
              Yes
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              sx={{ m: 1 }}
              onClick={cancelDeleteEndorsement}
            >
              No
            </Button>
          </Box>
        </Paper>
      )}
      {endorsements?.length === 0 ? (
        <>
          <Paper
            sx={{
              p: 2,
              m: 4,
              boxShadow: 5,
            }}
          >
            <Typography variant="h6">No endorsements available.</Typography>
          </Paper>
        </>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            {endorsements.map((endorsement) => (
              <Paper
                key={endorsement.email}
                sx={{
                  p: 2,
                  m: 4,
                  boxShadow: 5,
                }}
              >
                <Table stickyHeader size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                          Status:{" "}
                        </Typography>
                        <Typography variant="h6" sx={{ fontWeight: "bold", color: endorsement.status === "PENDING" ? '#F5D509' : 'green', marginBottom: 2 }}>
                          {endorsement.status}
                        </Typography>
                        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                          From:
                        </Typography>
                        <Typography variant="h6" sx={{ marginBottom: 2 }}>
                          {endorsement.email}
                        </Typography>
                        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                          First Name:
                        </Typography>
                        <Typography variant="h6" sx={{ marginBottom: 2 }}>
                          {endorsement.firstName}
                        </Typography>
                        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                          Last Name:
                        </Typography>
                        <Typography variant="h6" sx={{ marginBottom: 2 }}>
                          {endorsement.lastName}
                        </Typography>
                        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                          Sent on (YYYY-MM-DD):{" "}
                        </Typography>
                        <Typography variant="h6" sx={{ marginBottom: 2 }}>
                          {endorsement.createdAt}
                        </Typography>
                        {endorsement.status === "PENDING" && (
                          <>
                            <Typography
                              variant="h5"
                              sx={{ fontWeight: "bold" }}
                            >
                              Expires on:
                            </Typography>
                            <Typography variant="h6" sx={{ marginBottom: 2 }}>
                              {formatDate(endorsement.tokenExp)}
                            </Typography>
                          </>
                        )}
                      </TableCell>
                      <TableCell style={{ borderBottom: "none" }}>
                        {endorsement.status === "RECEIVED" && (
                          <>
                            <Typography
                              variant="h5"
                              sx={{ fontWeight: "bold" }}
                            >
                              Phone Number:
                            </Typography>
                            <Typography variant="h6" sx={{ marginBottom: 2 }}>
                              {endorsement?.phoneNumber}
                            </Typography>
                            <Typography
                              variant="h5"
                              sx={{ fontWeight: "bold" }}
                            >
                              Recieved On:
                            </Typography>
                            <Typography variant="h6" sx={{ marginBottom: 2 }}>
                              {endorsement?.updatedAt}
                            </Typography>
                            <Typography
                              variant="h5"
                              sx={{ fontWeight: "bold" }}
                            >
                              Body:
                            </Typography>
                            <Typography variant="h6" sx={{ marginBottom: 2 }}>
                              <Box sx={{ maxWidth: 600 }}>
                                {endorsement?.endorsementBody}
                              </Box>
                            </Typography>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        {" "}
                        {endorsement?.links && (
                          <>
                            {endorsement?.links.map((link) => (
                              <Button
                                className={classes.button}
                                variant="contained"
                                sx={{ m: 1 }}
                                key={link}
                                onClick={() => {
                                  if (
                                    link.rel.includes("Delete///Endorsement")
                                  ) {
                                    console.log("inside of: ", link.rel);
                                    handleDeleteEndorsement(link, endorsement);
                                  } else {
                                    console.log("Clicked on: ", link.rel);
                                  }
                                }}
                              >
                                {link.rel.replace(/\/\/\//g, " ")}
                              </Button>
                            ))}
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
            ))}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default AllBadgeEndorsements;
