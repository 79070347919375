import React, { useState } from "react";
import { CssBaseline, Typography, Box, Button, Paper, CircularProgress } from "@mui/material";
import useStyles from "../../styles/styles";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { EndorsementEndpoints } from "../../api/Endpoints";
import { sendEndpointRequest } from "../../iop/iop";
import { getCurrentUser } from "../../api/user";
import { fieldErrorHandlerCommon, FieldErrorMapEntry } from "../../api/validation";
import { ErrorType } from "../../api/APIErrors";

/* Field violations error map */
export const errorMap = [
  new FieldErrorMapEntry("email", ErrorType.ERR_INPUT_VALIDATION_INVALID_EMAIL, "toEmail", "Invalid email format."),
  new FieldErrorMapEntry("firstName", ErrorType.ERR_INPUT_VALIDATION_SIZE_EXCEEDED, "firstName", "First name must be less than 255 characters."),
  new FieldErrorMapEntry("lastName", ErrorType.ERR_INPUT_VALIDATION_SIZE_EXCEEDED, "lastName", "Last name must be less than 255 characters.")
]

const EndorsementRequestPopup = ({title, onClose }) => {
  const classes = useStyles();
  const [toEmail, setToEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [thisMessages, setThisMessages] = useState("");
  const [endorsementSent, setEndorsementSent] = useState(false);
  const [loading, setLoading] = useState(false);


  const handleSubmit = async () => {
    if (!toEmail || !firstName || !lastName) {
      setThisMessages("Email, first name, and last name are required.");
      return;
    }
    (async () => {
      try {
          /* Get current user info and fill data transfer object */
          const currentUser = await getCurrentUser();
          const dto = {
              email: toEmail,
              firstName: firstName,
              lastName: lastName,
              userId: currentUser.userId
          };
  
          /* Request the endorsement */
          const response = await sendEndpointRequest(EndorsementEndpoints.endpoints.requestEndorsement, null, dto);

          if(response.data?.success) {
            setEndorsementSent(true);
            setThisMessages("Endorsement was sent successfully!")
          } else {
            /* Display any field violation errors */
            fieldErrorHandlerCommon(response, errorMap);
          }

      } catch (error) {
        setThisMessages("An unknown error has occured. Please try again later.")
      }
  })();
    setLoading(false);
  };

  return (
    <>
      <CssBaseline />
      <Paper
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 999,
          backgroundColor: "white",
          boxShadow: 5,
        }}
      >
        <Box className={classes.InputFormFlex} sx={{ m: 4 }}>
          <Box>
            <Grid container spacing={3} maxWidth={700}>
              <Grid item xs={12} sm={12}>
                <Typography variant="h3">Endorsement Request</Typography>
                <Typography variant="h6">
                 {title}
                </Typography>
                <Typography variant="h6">
                  <strong>Send to:</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  id="firstName"
                  name="firstName"
                  label="First Name"
                  value={firstName}
                  fullWidth
                  variant="standard"
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <TextField
                  required
                  id="lastName"
                  name="lastName"
                  label="Last Name"
                  value={lastName}
                  fullWidth
                  variant="standard"
                  onChange={(e) => setLastName(e.target.value)}
                />
                <TextField
                  required
                  id="toEmail"
                  name="toEmail"
                  label="Email"
                  value={toEmail}
                  fullWidth
                  variant="standard"
                  onChange={(e) => setToEmail(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <br></br>
              {!loading && (
                <Box sx={{ width: "300px" }}>
                  {!endorsementSent && ( // Render the Submit button only if endorsement is not sent
                    <Button
                      className={classes.button}
                      onClick={handleSubmit}
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Submit
                    </Button>
                  )}
                  <Button
                    className={classes.button}
                    onClick={onClose}
                    fullWidth
                    variant="contained"
                    sx={{ mb: 2 }}
                  >
                    Close
                  </Button>
                  {endorsementSent && (
                    <Grid item xs={12} sm={12}>
                      <Typography variant="h6" style={{ color: 'green' }}>{thisMessages}</Typography>
                    </Grid>
                  )}
                </Box>
              )}
              {loading &&(
                <CircularProgress />
              )}

            </Grid>
            {!endorsementSent && !loading && (
              <Grid item xs={12} sm={12}>
                <Typography variant="h6" style={{ color: 'red' }}>{thisMessages}</Typography>
              </Grid>
            )}
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default EndorsementRequestPopup;
