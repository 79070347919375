import React, { useState } from 'react';
import { config } from '../../Constants';


// Define the functional component
const CreateSkill = ({ setSkill }) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const getToken = () => {
    console.log("Token: ", localStorage.getItem('token'));
    return localStorage.getItem('token');
  };

  const handleButtonClick = async () => {
    try {
      const isConfirmed = window.confirm("Are you sure you want to create this skill?");
      if (!isConfirmed) return; // Exit if the user cancels
      const apiUrl = `${config.url.API_BASE_URL}/api/skill/create-skill`;
  
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({ title: inputValue }),
      });
  
      const result = await response.json();
      console.log('API Response:', result);
      
      // Check for errors in the result
      if (result.success === false) {
        throw new Error(result.error.detail); // Create a new Error object
      }
  
      // Pass the skill data back to the parent component
      setSkill(result.data); // Assume result.data contains the created skill
      setInputValue(''); // Clear the input field after submission
  
      alert(`New Skill Created! ${JSON.stringify(result.data)}`);
    } catch (error) {
      console.error('Error during API call:', error);
      alert(error); // Display a user-friendly message
    }
  };
  

  return (
    <div style={{ maxWidth: '400px', margin: '5px auto', padding: '20px', border: '1px #ccc', borderRadius: '10px' }}>
      <label htmlFor="textInput" style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>
        Enter New Skill Title:
      </label>
      
      <input
        type="text"
        id="textInput"
        value={inputValue}
        onChange={handleInputChange}
        style={{ width: '100%', padding: '8px', marginBottom: '5px', borderRadius: '5px', border: '1px solid #ccc' }}
      />
      
      <button
        onClick={handleButtonClick}
        style={{
          width: '100%',
          padding: '10px',
          backgroundColor: inputValue.trim() ? '#590D82' : '#D1A6E6', // Green if active, light green if disabled
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: inputValue.trim() ? 'pointer' : 'not-allowed', // Change cursor based on state
          opacity: inputValue.trim() ? 1 : 0.6, // Set opacity to grey out if disabled
        }}
        disabled={!inputValue.trim()} // Disable button if inputValue is empty
      >
        Submit
      </button>
    </div>
  );
};

export default CreateSkill;
