import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { config } from '../../Constants';
import axios from 'axios';
import { debounce } from 'lodash';
import Switch from '@mui/material/Switch';

const OwnerRTE = ({ skill }) => {

    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const typingTimeoutRef = useRef(null);
    const [content, setContent] = useState('');
    //const [uploadedImages, setUploadedImages] = useState([]);
    const elementRef = useRef(null);
    const [isVisible, setIsVisible] = React.useState(false);


    const getToken = () => {
        const token = localStorage.getItem('token');
        return token ? token.toString() : '';
    };

    const handleResourceRedirection = async () => {
        try {
            const skillTitle = skill.title;
            const newTab = window.open(`/skill-resource/${skillTitle}`, '_blank');

            if (newTab) {
                newTab.focus();
            } else {
                console.error('{OwnerRTE} Failed to open new tab');
            }
        } catch (error) {
            console.error(`{OwnerRTE} handleResourceRedirection() Error Response: }`, error);
        }
    };  

    const handleSaveContent = async (contentToSave) => {
        if (!contentToSave) return;
        setSaving(true);

        try {
            const response = await axios.post(
                `${config.url.API_BASE_URL}/api/skill/save-skill-content`,
                {
                    content: contentToSave,
                    skillId: skill.skillId
                },
                {
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    },
                });

            console.log("Response: ", response.data, "Content: ", contentToSave);

            if (response.data !== contentToSave) {
                setContent(response.data.data);
            }

            if (response.data !== contentToSave) {
                setContent(response.data);
            } console.log(`{OwnerRTE} Save Skill Content Success Response: `, response);
            await new Promise(resolve => setTimeout(resolve, 1000));

        } catch (error) {
            console.error(`{OwnerRTE} Save Skill Content Error Response: `, error);
            alert("Error Saving Skill Content");
            await new Promise(resolve => setTimeout(resolve, 1000));

        } finally {
            setSaving(false);
        }
    };  

    const handleChange = (event, editor) => {
        const data = editor.getData();
        setContent(data); 

        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }

        typingTimeoutRef.current = setTimeout(() => { 
            handleSaveContent(data);
        }, 1000); 
        
    };

    const uploadImage = async (file) => {
        const formData = new FormData();
        formData.append('image', file);

        const response = await fetch(`${config.url.API_BASE_URL}/api/skill/upload-image?skill-id=${skill.skillId}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
            body: formData,
        });

        const result = await response.json();
        //setUploadedImages((prev) => [...prev, result.data]);  // Add the image URL
        return result;
    };

    // const deleteImageFromServer = async (imageUrl) => {
    //     setLoading(true);

    //     if (!imageUrl) {
    //         return;
    //     }

    //     try {
    //         const imageKey = imageUrl.split('amazonaws.com/')[1];  // Extract image key from URL
    //         await axios.put(`${config.url.API_BASE_URL}/api/skill/delete-image`, null, {
    //             params: { 'image-key': imageKey },
    //             headers: { 'Authorization': `Bearer ${getToken()}` }
    //         });
    //     } catch (error) {
    //         console.error("{OwnerRTE} Delete Image Error Response: ", error);
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    const onEditorReady = (editor) => {
        console.log("Editor is ready", editor);
        
        // const deleteCommand = editor.commands.get('delete');
        // const deleteForwardCommand = editor.commands.get('deleteForward');  // forward delete (backspace)
    
        // if (deleteCommand) {
        //     deleteCommand.on('execute', () => {
        //         console.log("Delete command executed");
        //         handleImageDelete(editor);
        //     });
        // }
    
        // if (deleteForwardCommand) {
        //     deleteForwardCommand.on('execute', () => {
        //         console.log("DeleteForward command executed");
        //         handleImageDelete(editor);
        //     });
        // }
    };

    // const handleImageDelete = (editor) => {
    //     console.log("{OwnerRTE} handleImageDelete");
    //     console.log("{OwnerRTE} editor: ", editor);

    //     const selection = editor.model.document.selection;
    //     const selectedElement = selection.getSelectedElement();
    //     console.log("{OwnerRTE} selection: ", selection);
    //     console.log("{OwnerRTE} selectedElement: ", selectedElement);
        

    //     // Check if there are ranges selected
    //     const selectedRanges = selection.getRanges();
    //     console.log("{OwnerRTE} selectedRanges: ", selectedRanges);

    //     if (selectedRanges.length > 0) {
    //         selectedRanges.forEach(range => {
    //             const startNode = range.start.nodeAfter;
    //             const endNode = range.end.nodeBefore;

    //             console.log("{OwnerRTE} startNode: ", startNode);
    //             console.log("{OwnerRTE} endNode: ", endNode);

    //             // Check if the start or end node is an image
    //             if (startNode && startNode.is('element', 'image')) {
    //                 const imageUrl = startNode.getAttribute('src');
    //                 console.log("{OwnerRTE} Image selected for deletion:", imageUrl);
    //                 setExplicitlyDeletedImages((prev) => [...prev, imageUrl]);
    //                 deleteImageFromServer(imageUrl);
    //             } else if (endNode && endNode.is('element', 'image')) {
    //                 const imageUrl = endNode.getAttribute('src');
    //                 console.log("{OwnerRTE} Image selected for deletion:", imageUrl);
    //                 setExplicitlyDeletedImages((prev) => [...prev, imageUrl]);
    //                 deleteImageFromServer(imageUrl);
    //             } else {
    //                 console.log("{OwnerRTE} Text deleted, but no image involved.");
    //             }
    //         });
    //     } else {
    //         console.log("No selection made.");
    //     }
    // };
    
    class MyUploadAdapter {
        constructor(loader) {
            this.loader = loader;
        }

        upload() {
            return this.loader.file.then((file) => {
                return new Promise((resolve, reject) => {
                    setLoading(true);
                    uploadImage(file)
                        .then((result) => {
                            resolve({ default: result.data });
                        })
                        .catch((error) => {
                            console.error("{OwnerRTE} Upload Image Error: ", error);
                            reject(error);
                        })
                        .finally(() => {
                            setLoading(false);
                        });
                });
            });
        }

        abort() {}
    }

    function CustomUploadAdapterPlugin(editor) {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return new MyUploadAdapter(loader);
        };
    }

    const fetchContent = async () => {
        if (!skill.skillId) return;

        setLoading(true);
        try {
            const response = await axios.get(`${config.url.API_BASE_URL}/api/skill/${skill.title}`, {
                headers: { Authorization: `Bearer ${getToken()}` }
            });
            const newContent = response.data.txtContent || '';
            setContent((prevContent) => newContent !== prevContent ? newContent : prevContent);
            console.log("{OwnerRTE} Fetch Content Success: ", response);
            setIsVisible(response.data.attributes.isPublic);
        } catch (error) {
            console.error("{OwnerRTE} Fetch Content Error: ", error);
        } finally {
            await new Promise(resolve => setTimeout(resolve, 1000));
            setLoading(false);
        }
    };

    const handleSkillVisibilty = (event) => {
        if (event.target.checked == false) {
            alert("Can only switch to visible, support for switching to not visible in progress");
            return;
        }
        setIsVisible(event.target.checked);
        console.log("handleSkillVisibilty isVisibile: ", event.target.checked);
        publishSkill();
    };

    const publishSkill = async() => {

        console.log("publishSkill, skillId: ", skill.skillId);
        try {
            const response = await axios.put(
                `${config.url.API_BASE_URL}/api/skill/publish-skill`,
                { 
                    skillId: skill.skillId
                },
                {
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
              );
            console.log("{OwnerRTE} publishSkill: ", response);

        } catch (error) {
            console.error("{OwnerRTE} ERROR publishSkill() : ", error);
        }

    }
    useEffect(() => {
        fetchContent();
    }, [skill]);

    useEffect(() => {
        const handleResize = debounce(() => {
            console.log('Resized:', elementRef.current.offsetWidth);
        }, 100);

        const resizeObserver = new ResizeObserver(handleResize);
        if (elementRef.current) {
            resizeObserver.observe(elementRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    return (
        <div className="editor-container">
            <p>{config.url.UI_BASE_URL}/skill-resource/{skill.title}</p>
            <div className="header-container">
                <h4>Selected Skill: {skill?.title}</h4>
                <Switch checked={isVisible} onChange={handleSkillVisibilty} disabled={loading || saving}/>
                <button className="right-button" onClick={handleResourceRedirection} disabled={loading || saving}>
                See Rendered Resource
                </button>
                <button
                    style={{ width: '130px', height: '50px' }}
                    className="right-button" onClick={handleSaveContent} disabled={loading || saving}>
                    {loading ? 'Loading..' : saving ? 'Saving..' : 'Save Content'}
                </button>
            </div>
            <CKEditor
                editor={ClassicEditor}
                data={content}
                onChange={handleChange}
                onReady={onEditorReady}
                config={{
                    extraPlugins: [CustomUploadAdapterPlugin],
                    toolbar: [
                        'heading', '|', 'bold', 'italic', 'underline', '|',
                        'link', 'bulletedList', 'numberedList', '|',
                        'blockQuote', 'imageUpload', '|', 
                        'undo', 'redo', 'removeFormat'
                    ],
                }}
            />
        </div>
    );
};

export default OwnerRTE;
