import React, { useState, useContext, useEffect } from "react";
import { UserContext, DashboardContext } from "../../provider";
import { useLocation, Link } from "react-router-dom";
import ErrorSection from "../utils/ErrorsSection";
import { getRequest } from "../../api/helper";
import { headers } from "../../api/user";
import axios from "axios";
import { config } from "../../Constants";
import { CssBaseline, Typography, Paper, Box, Button } from "@mui/material";
import useStyles from "../../styles/styles";
import theme from "../../styles/theme";
import { ThemeProvider } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import BadgeImage from "../BadgeImage";

const AddBadgeToOrg = () => {
  const classes = useStyles();
  const { state } = useLocation();
  const { url } = state;

  const [allBadges, setAllBAdges] = useState([]);
  const [orgBadges, setOrgBAdges] = useState([]);
  const { board, loadOrganizations } = useContext(DashboardContext);
  const { setErrors, errors, navigate } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [orgId, setSelectedOrg] = useState();
  const [orgName, setOrgName] = useState("");

  const fetchAllBadges = async () => {
    try {
      const allBadgesData = await getRequest(
        `${config.url.API_BASE_URL}/api/badge/all`
      );
      console.log("All Badges Endpoint in AddBadgeToOrg: ", allBadgesData);
      setAllBAdges(allBadgesData);
    } catch (error) {
      console.error("Error fetching all badges: ", error);
      setErrors(error.response);
    }
  };

  const fetchOrgDetails = async () => {
    try {
      console.log("Component Prop: ", url);
      const orgIdFromLink = url.match(/orgId=([^&]+)/);
      console.log("Extracted Org Id: ", orgIdFromLink);
      setSelectedOrg(orgIdFromLink[1]);

      const orgDetails = await getRequest(
        `${config.url.API_BASE_URL}/api/organization/detail/${orgIdFromLink[1]}`
      );
      console.log("Org Detail from useEffect link state: ", orgDetails);
      setOrgName(orgDetails.name);
      setOrgBAdges(orgDetails.badges || []);
    } catch (error) {
      console.error("Error fetching organization details: ", error);
      setErrors(error.response);
    }
  };

  useEffect(() => {
    fetchAllBadges();
    fetchOrgDetails();
  }, []);

  const addBadgeToOrg = async (link, org) => {
    console.log("ORGGGGId", org);
    const modifiedLink = link.replace("{orgId}", org);

    console.log("URLLLLLLLL: ", modifiedLink);
    try {
      const response = await axios.post(modifiedLink, null, {
        headers: headers(),
      });
      console.log("response: ", response);
      fetchOrgDetails();
      setErrors(null);
    } catch (error) {
      alert(error.response.data.error.detail);
      console.error("ERROR: ", error);
    }
  };

  const removeBadge = async (link) => {
    try {
      const response = await axios.post(link, null, { headers: headers() });
      console.log("response: ", response);
      fetchOrgDetails();
      setErrors(null);
    } catch (error) {
      setErrors(error.response);
      console.error("ERROR: ", error);
    }
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Typography className={classes.TableTitle} variant="h3">
          Organization Badges
        </Typography>
        <Table stickyHeader size="small">
          <TableBody>
            {orgBadges &&
              orgBadges.map((badge) => (
                <Paper
                  sx={{
                    p: 2,
                    m: 4,
                    boxShadow: 5,
                  }}
                >
                  <TableRow key={badge.title}>
                    <TableCell>
                      <BadgeImage key={badge.email} badge={badge} />
                    </TableCell>
                    <TableCell style={{ borderBottom: "none" }}>
                      <Box>
                        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                          {badge.title}
                        </Typography>
                        <Typography variant="h6">
                          {badge.description}
                        </Typography>
                        <Typography variant="h6">
                          Minimum Endorsements: {badge.minimumEndorsements}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell style={{ borderBottom: "none" }}>
                      {badge._links &&
                        Object.keys(badge._links).map((linkKey) => (
                          <Button
                            className={classes.button}
                            onClick={() =>
                              removeBadge(badge._links[linkKey].href)
                            }
                            variant="contained"
                            sx={{ m: 1 }}
                          >
                            {linkKey.replace(/\/\/\//g, " ")}
                          </Button>
                        ))}
                    </TableCell>
                  </TableRow>
                </Paper>
              ))}
          </TableBody>
        </Table>
        <Typography className={classes.TableTitle} variant="h3">
          All Badges
        </Typography>
        <Table stickyHeader size="small">
          <TableBody>
            {allBadges.map((badge) => (
              <Paper
                sx={{
                  p: 2,
                  m: 4,
                  boxShadow: 5,
                }}
              >
                <TableRow key={badge.title}>
                  <TableCell>
                    <BadgeImage key={badge.email} badge={badge} />
                  </TableCell>
                  <TableCell style={{ borderBottom: "none" }}>
                    <Box>
                      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                        {badge.title}
                      </Typography>
                      <Typography variant="h6">{badge.description}</Typography>
                      <Typography variant="h6">
                        Minimum Endorsements: {badge.minimumEndorsements}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell style={{ borderBottom: "none" }}>
                    {badge.links.map((link, linkIndex) => (
                      <Button
                        className={classes.button}
                        onClick={() => addBadgeToOrg(link.href, orgId)}
                        variant="contained"
                        sx={{ m: 1 }}
                      >
                        {link.rel.replace(/\/\/\//g, " ")}
                      </Button>
                    ))}
                  </TableCell>
                </TableRow>
              </Paper>
            ))}
          </TableBody>
        </Table>
      </ThemeProvider>
    </>
  );
};

export default AddBadgeToOrg;
