import React from "react";
import { CssBaseline, Typography } from "@mui/material";
import useStyles from "../../styles/styles";

const Loading = () => {
  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <div className="loading">
        <Typography variant="h1">Loading...</Typography>
      </div>
    </>
  );
};

export default Loading;
