import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from "../../provider";
import { config } from '../../Constants';
import axios from 'axios';

const UserSelectSkillDescriptors = ({ skill, onContentUpdate, reset }) => { // Accept a prop 'onContentUpdate'
    const { user } = useContext(UserContext);
    const [content, setContent] = useState(null); // Initialize content as null
    const [skillDescriptor, setSkillDescriptor] = useState(''); // Initialize descriptor as empty string
    const [descriptorList, setDescriptorList] = useState([]);

    const getToken = () => {
        const token = localStorage.getItem('token');
        return token ? token.toString() : '';
    };

    const fetchContent = async () => {
        try {
            const response = await axios.get(`${config.url.API_BASE_URL}/api/skill/${user.email}/${skill.title}`, {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            console.log("RECIEVED USER SKILL DESCRIPTORS: ", response.data);
            setDescriptorList(response.data.length > 0 ? response.data : []);
        } catch (error) {
            console.error("Error fetching skill content: ", error);
            if (error.response?.data?.error?.type === "API_ERROR_GENERIC_NOT_FOUND") {
                setDescriptorList([]);  // If not found, ensure descriptorList is empty
            }
        }
    };

    const handleCreateSkillDescriptor = async () => {
        try {
            console.log("SKILLTITLE: ", skill.title, "DESCRIPTOR: ", skillDescriptor);
    
            const response = await axios.post(
                `${config.url.API_BASE_URL}/api/skill/create-new-evidence`, 
                {
                    skillTitle: skill.title,
                    contentTitle: skillDescriptor
                },
                {
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    }
                }
            );
            setSkillDescriptor('');
            console.log("RECEIVED USER SKILL DESCRIPTORS: ", response);
        } catch (error) {
            console.log("ERROR RETRIEVING USER SKILL DESCRIPTORS: ", error);
        }
    };

    const fetchEvidenceContext = (contentTitle) => {
        console.log("CONTENT: ", contentTitle);
        if (contentTitle)
        {
            onContentUpdate(contentTitle); // Pass the contentTitle data to the parent
            return;
        }
        else 
        {
            reset();
        }
    };
    

    useEffect(() => {
        fetchContent(); // Fetch content when component mounts
    }, [skill.skillId, skillDescriptor]);

    return (
        <div>
            {/* Check if content is null or empty, and show message, textbox, and button */}
            {descriptorList.length === 0 ? ( // Handles content being null, undefined, or empty
                <div>
                    <p>No evidence yet for {skill.title}! Please create a descriptor!</p>
                    <input 
                        type="text" 
                        placeholder="Enter your content" 
                        value={skillDescriptor} 
                        onChange={(e) => setSkillDescriptor(e.target.value)} 
                        style={{ width: '100%', padding: '8px', marginBottom: '5px', borderRadius: '5px', border: '1px solid #ccc' }}
                    />
                    <button
                        onClick={handleCreateSkillDescriptor}
                        style={{
                        width: '100%',
                        padding: '10px',
                        backgroundColor: skillDescriptor.trim() ? '#007bff' : '#99ccff', // Blue if active, light blue if disabled
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: skillDescriptor.trim() ? 'pointer' : 'not-allowed', // Change cursor based on state
                        opacity: skillDescriptor.trim() ? 1 : 0.6, // Set opacity to grey out if disabled
                        }}
                        disabled={!skillDescriptor.trim()} // Disable button if inputValue is empty
                    >
                        Submit
                    </button>
                </div>
            ) : (
                <div>
                    {/* Render the dropdown if descriptorList is available and is an array */}
                    {descriptorList && Array.isArray(descriptorList) && descriptorList.length > 0 ? (
                        <div style={{ maxWidth: '400px', margin: '5px auto', padding: '20px', border: '1px #ccc', borderRadius: '10px' }}>
                            <label>Select Evidence:</label>
                            <div>
                    <p>Create new Evidence!</p>
                    <input 
                        type="text" 
                        placeholder="Enter your content" 
                        value={skillDescriptor} 
                        onChange={(e) => setSkillDescriptor(e.target.value)} 
                        style={{ width: '100%', padding: '8px', marginBottom: '5px', borderRadius: '5px', border: '1px solid #ccc' }}
                    />
                    <button
                        onClick={handleCreateSkillDescriptor}
                        style={{
                        width: '100%',
                        padding: '10px',
                        backgroundColor: skillDescriptor.trim() ? '#007bff' : '#99ccff', // Blue if active, light blue if disabled
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: skillDescriptor.trim() ? 'pointer' : 'not-allowed', // Change cursor based on state
                        opacity: skillDescriptor.trim() ? 1 : 0.6, // Set opacity to grey out if disabled
                        }}
                        disabled={!skillDescriptor.trim()} // Disable button if inputValue is empty
                    >
                        Submit
                    </button>
                </div>
                            <select 
                                onChange={(e) => fetchEvidenceContext(e.target.value)}
                                style={{ width: '100%', padding: '8px', marginBottom: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
                            >
                                <option value="">-- Select Evidence --</option>
                                {descriptorList.map((item, index) => (
                                    <option key={index} value={item.skillTitle}>
                                        {item.skillTitle || 'Untitled'} {/* Display the skillTitle */}
                                    </option>
                                ))}
                            </select>
                        </div>
                    ) : (
                        <p>No content available</p>
                    )}
                </div>

            )}
        </div>
    );
};

export default UserSelectSkillDescriptors;
