import axios from 'axios';
import { config } from '../Constants';
import { RegistrationEndpoints } from "./Endpoints";
import { sendEndpointRequest } from "../iop/iop";
import { sendEndpointRequestNoAuth  } from '../iop/iop';
import { ErrorType } from './APIErrors';

export async function  login  (username, password) {
    try{
        const response = await axios.post(`${config.url.API_BASE_URL}/api/auth/authenticate`, {email: username, password} , { headers })
        console.log('Login successful:', response.data);
        localStorage.setItem('token', response.data.token)
        return response.data.token
    }catch(err)  {
      console.error('In Login Func, Attempting login at:', `${config.url.API_BASE_URL}/api/auth/authenticate`);
      console.error('Login failed:', err);
      throw err
    }
  };

  export  const headers = () => {
    const token = localStorage.getItem('token').toString();
    return {
      Authorization: `Bearer ${token}`,
      Accept: '*/*',
      "Content-Type" : "application/json"
    }
  }

 export const  getCurrentUser = async () => {
    try{
      console.log({headers: headers()})
      const response = await axios.get(`${config.url.API_BASE_URL}/api/user/me`,{headers: headers()})
      console.log(response)
      return response.data
    }catch(err){
      console.error('getCurrentUser failed:', err);
      throw err
    }
  }

  /**
   * 
   * @param {string} username 
   * @param {string} password 
   * @param {string} firstName 
   * @param {string} lastName 
   * @param {string} orgName 
   * @returns 
   */
  export async function registerNewAccount(username, password, firstName, lastName, orgName) {
    const data = {
      email : username,
      password : password,
      firstName : firstName,
      lastName : lastName,
      orgName: orgName
    }

    const endpoint = RegistrationEndpoints.endpoints.createNewAccount;
    return( await sendEndpointRequestNoAuth(endpoint, null, data) );
  };

  export const registerNewAdminAccount = async (username, password, firstName, lastName, orgName) => {
    const data = {
      email : username,
      password : password,
      firstName : firstName,
      lastName : lastName,
      orgName: orgName
    }
    const endpoint = RegistrationEndpoints.endpoints.createAdminAccount;
    return( await sendEndpointRequest(endpoint, null, data) );

  };
  

  export const softDeleteUser = async (link) => {
    try {
      const email = decodeURIComponent(link.split('?email=')[1]);
      const url = `${config.url.API_BASE_URL}/api/owner/soft-delete-user?email=${email}`;
      console.log({ headers: headers() }, { "url": url });
      const response = await axios.post(url, null, { headers: headers() });
      console.log("Soft Delete: ", response);
      return response.data;
    } catch (err) {
      console.error('Soft Delete User failed:', err);
      throw err;
    }
  };


  export const verifyUsers = async (userEmails) => {
    const token = localStorage.getItem('token').toString();
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "*/*",
      "Content-Type" : "application/json"
    };
    const data = {
      emails: userEmails
    }
    const url = `${config.url.API_BASE_URL}/api/owner/manual-verification`;
    try{
      console.log(url)
      const response = await axios.post(url, data, {headers: headers})
      console.log("Verifying users uucceeded: ", response)
      return response.data
    }catch(err){
      console.error('Verifying users failed:', err);
      throw err
    }
  };
