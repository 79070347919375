import React, { useState, useEffect } from 'react';
import { config } from '../../Constants';


// Define the functional component
const UserSelectSkills = ({ setSkill, reset }) => { // Add refreshKey to the props
  const [skills, setSkills] = useState([]); // State to store the list of skills
  const [selectedSkill, setSelectedSkill] = useState(null); // State to store the selected skill

  // Function to get the token from localStorage
  const getToken = () => {
    return localStorage.getItem('token');
  };

  // Function to fetch the list of skills
  const fetchExistingSkills = async () => {
    try {
      const apiUrl = `${config.url.API_BASE_URL}/api/skill/get-skill-list-public`;
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        },
      });

      const result = await response.json();
      console.log('API Response:', result);

      // Set the skills in the component's state
      setSkills(result.data); // Assume result.data contains the skills array
    } catch (error) {
      console.error('Error fetching existing skills:', error);
      alert('Failed to load existing skills. Please try again.');
    }
  };

  // Fetch existing skills whenever the component mounts or refreshKey changes
  useEffect(() => {
    fetchExistingSkills();
  }, []);

  // Function to handle skill selection
  const handleSkillChange = (event) => {
    const selectedSkill = skills.find(skill => skill.skillId === event.target.value); // Assuming 'skillId' is the unique identifier
    setSelectedSkill(selectedSkill);
    console.log("SELECTED USER SKILL: ", selectedSkill);
    setSkill(selectedSkill); // Pass the selected skill to the parent component
    reset();
  };

  return (
    <div style={{ maxWidth: '400px', margin: '5px auto', padding: '20px', border: '1px #ccc', borderRadius: '10px' }}>
      <label htmlFor="skillDropdown" style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>
        Select Existing Skill:
      </label>
      
      {/* Dropdown to select skills */}
      <select
        id="skillDropdown"
        value={selectedSkill ? selectedSkill.skillId : ''} // Bind selected skill's id to the dropdown
        onChange={handleSkillChange}
        style={{ width: '100%', padding: '8px', marginBottom: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
      >
        <option value="">Select a skill...</option>
        {skills.map(skill => (
          <option key={skill.skillId} value={skill.skillId}>
            {skill.title} {/* Display the skill's title */}
          </option>
        ))}
      </select>
    </div>
  );
};

export default UserSelectSkills;
