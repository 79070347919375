import { UserContext } from "../../../provider";
import React, { useState, useEffect, useContext } from "react";
import useStyles from "../../../styles/styles";
import { UserEndpoints } from "../../../api/Endpoints";
import { sendEndpointRequestFile, sendEndpointRequestNoAuth } from "../../../iop/iop";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import { Navigate } from "react-router-dom";

const UserProfilePane = ({ username }) => {
  const { user } = useContext(UserContext);
  const classes = useStyles();
  const [image, setImage] = useState("https://via.placeholder.com/250");

  const [viewingOwnProfile, setViewingOwnProfile] = useState(false);
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    if (user && user.email === username) {
      setViewingOwnProfile(true);
    } else {
      setViewingOwnProfile(false);
    }
  }, [user, username]);

  useEffect(() => {
    const getUserData = async () => {

      const params = { username };
      try {
        const response = await sendEndpointRequestNoAuth(UserEndpoints.endpoints.getUserContext, params, null, null);
        if (response && response.data) {
          setUserFirstName(response.data.firstName);
          setUserLastName(response.data.lastName);
          setImage(response.data.avatarUrl || "https://via.placeholder.com/250");
        } else {
          setNotFound(true);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        toast.error("An error occurred while fetching user data.");
      }
    };

    getUserData();
  }, [username, user, viewingOwnProfile]);


  const handleEditProfile = () => {
    // TODO: Implement profile edit
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await sendEndpointRequestFile(UserEndpoints.endpoints.uploadUserAvatar, null, formData);
      if (response.success) {
        toast.success("Image updated successfully.");
        setAvatarImage(file);
      } else {
        toast.error("Failed to update profile avatar!");
      }
    } catch (error) {
      console.error("Error uploading avatar:", error);
      toast.error("An error occurred while uploading the avatar.");
    }
  };

  const setAvatarImage = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleEditAvatarClick = () => {
    document.getElementById("avatar-upload").click();
  };

  if (notFound) {
    return <Navigate to="/404" replace />;
  }

  return (
    <section>
      <div className={classes.profileSection}>
        <div className={classes.profileAvatarContainer}>
          <img src={image} alt="User Avatar" className={classes.profileAvatar} />
          {viewingOwnProfile && (
            <button className={classes.editAvatarButton} onClick={handleEditAvatarClick}>
              <EditIcon />
            </button>
          )}
        </div>
        {userFirstName && userLastName && (
          <>
            <h2 className={classes.profileName}>
              <span className={classes.profileFirstName}>{userFirstName}</span>
              <span className={classes.profileLastName}>{userLastName}</span>
            </h2>
            <p className={classes.profileTitle}>Senior Systems Analyst</p>
            {viewingOwnProfile && (
              <button className={classes.editProfileButton} onClick={handleEditProfile}>
                Edit Profile
              </button>
            )}
          </>
        )}
        <input
          id="avatar-upload"
          type="file"
          hidden
          onChange={handleFileChange}
          accept="image/*"
        />
      </div>
    </section>
  );
};

export default UserProfilePane;
