/**
 * Network layer module  for processing IP requests.
 */
import axios from "axios";
import { config } from "../Constants";
import { Endpoint } from "../api/APIManager";
import { TokenError } from "../iop/tkn";

/**
 * Send a network request to the API.
 * 
 * @param {boolean} useTokenHeader - Whether or not to use the token header.
 * @param {Endpoint} endpoint      - The endpoint to send the request to.
 * @param {object} parms           - The parameters to send with the request (as an object).
 * @param {string} data            - The data/body to send with the request (usually
 *                                   JSON or something that can be serialized as JSON).
 * @param {object} pathVars        - The path variables to include in the request URL.
 * @returns
 */
export async function netSendRequest(headerType, endpoint, parms, data, pathVars) {
  const requestMethods = {
      'Post'   : axios.post,
      'Get'    : axios.get,
      'Put'    : axios.put,
      'Delete' : axios.delete
  };

  axios.useGResponse = true;

  /* Retrieve the request method from the requestMethods object */
  let requestMethod = requestMethods[endpoint.requestMethod];

  let response = null;
  try {
      let url = `${config.url.API_BASE_URL}${endpoint.subpath}`;

      /* Add any path variables to the target URL */
      if (pathVars && typeof pathVars === 'object') {
        for (const [key, value] of Object.entries(pathVars)) {
            const placeholder = `{${key}}`;
            url = url.replace(placeholder, value);
        }
    }

      /* Build the query string from params object */
      if (parms && typeof parms === 'object') {
          const queryString = new URLSearchParams(parms).toString();
          url += `?${queryString}`;
      }

      if (data == null) {
          data = {};
      }

      response = await requestMethod(url, data, { headers: headerType() });
  } catch (error) {  /* Intentional error throw to IOP layer */
      throw error;
  } finally {
      axios.useGResponse = false;
  }

  return response;
}


 /**
  * HTTP Headers used to send requests to the API.
  * @returns
  */
 const tknHeaders = () => {
    const token = getClientToken();
    return {
      Authorization: `Bearer ${token}`,
      Accept: '*/*',
      "Content-Type" : "application/json"
    }
  }

const unauthHeaders = () => {
    return {
      Accept: '*/*',
      "Content-Type" : "application/json"
    }
  }

const fileHeaders = () => {
  const token = getClientToken();
  return {
    Authorization: `Bearer ${token}`,
    Accept: '*/*',
    "Content-Type" : "application/json",
    "Content-Type": "multipart/form-data",
  }
}

const getClientToken = () => {
  let tokenStr = localStorage.getItem('token');
  if (tokenStr) {
    return tokenStr;
  }

  /* Unable to get the token from local storage, we have to assume 
   * we just don't have one. Throw this back to the IOP layer for
   * processing.
   */
  throw new TokenError("No token found in local storage.");

}

/* Transmission header types */
export const TXHeaderType = {
  AUTH: tknHeaders,
  NO_AUTH: unauthHeaders,
  FILE: fileHeaders
}