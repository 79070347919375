import React, { useState, useContext, useEffect } from "react";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import ErrorSection from "../utils/ErrorsSection";
import { UserContext } from "../../provider";
import SubmissionPopup from "../popups/SubmissionPopup";
import axios from "axios";
import theme from "../../styles/theme";
import { config } from "../../Constants";
import { CssBaseline, ThemeProvider, Typography, Paper } from "@mui/material";
import useStyles from "../../styles/styles";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/system/Box";
import { EndorsementEndpoints } from "../../api/Endpoints";
import { sendEndpointRequestNoAuth } from "../../iop/iop";
import { fieldErrorHandlerCommon, FieldErrorMapEntry } from "../../api/validation";
import { ErrorType } from "../../api/APIErrors";

/* Field violations error map */
export const errorMap = [
  new FieldErrorMapEntry("phoneNumber", ErrorType.ERR_INPUT_VALIDATION_PHONE_NUMBER_FORMAT, "phoneNumber", "Invalid phone number."),
]

const Endorsement = () => {
  const classes = useStyles();
  const { state } = useLocation();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [token, setToken] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [fromFirstName, setFromFirstName] = useState("");
  const [fromLastName, setFromLastName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [endorsement, setEndorsement] = useState("");
  const [eventContext, setEventContext] = useState([]);
  const [submissionMessage, setSubmissionMessage] = useState("");
  const [maxCharacters] = useState(1000);
  const { errors, setErrors } = useContext(UserContext);

  const loadContext = async (token) => {
    const headers = {
      Accept: "*/*",
      "Content-Type": "application/json",
    };
    try {
      const url =
        `${config.url.API_BASE_URL}/api/endorsement/by-token?token=` + token;
      console.log("URL to load context: ", url);
      const response = await axios.get(url, headers);
      setEventContext(response.data);
      console.log(response.data);
    } catch (err) {
      console.error("Failed Loading context", err);
    }
  };

  useEffect(() => {
    const tokenFromParams = searchParams.get("token");
    const firstNameFromParams = searchParams.get("firstName");
    const lastNameFromParams = searchParams.get("lastName");

    if (tokenFromParams) {
      setToken(tokenFromParams);
      setFromFirstName(firstNameFromParams);
      setFromLastName(lastNameFromParams);
      loadContext(tokenFromParams);
    } else {
      setErrors("Not found in search params");
    }
  }, [searchParams, setErrors]);

  const submitEndorsement = async () => {
    setErrors(null);
    if (endorsement == "" || number == "") {
      setErrors("All fields are required");
      return;
    }

    let response;
    try {
      const params = {token: token}
      const body = {
        endorsementBody: endorsement,
        phoneNumber: number,
      };
      response = await sendEndpointRequestNoAuth(EndorsementEndpoints.endpoints.saveEndorsement, params, body)

    } catch (err) {
      console.error(err);
      setSubmissionMessage("Error submitting endorsement: ", err,);
    }

    if(response.success) {
      navigate("/login");
    } else {
      fieldErrorHandlerCommon(response, errorMap);
    }

  };

  const closePopup = () => {
    setSubmissionMessage(null);
  };

  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Box className="inputFormFlex">
          <Grid container spacing={3} maxWidth={700}>
            <Grid item xs={12} sm={12}>
              {submissionMessage || (
                // Display the form sections when there's no submission message
                <Box className="mainSection">
                  {/* User Input Sections */}
                  <Typography variant="h2" className={classes.FormCaption}>
                    Endorsement
                  </Typography>
                  <TextField
                    required
                    id="phoneNumber"
                    name="phoneNumber"
                    label="Phone Number (max 15 characters)"
                    value={number}
                    fullWidth
                    variant="standard"
                    onChange={(e) => setNumber(e.target.value)}
                  />
                  <Grid item xs={12} sm={12}>
                    <TextField
                      id="endorsement"
                      name="endorsement"
                      label="Endorsement"
                      value={endorsement}
                      fullWidth
                      variant="standard"
                      multiline
                      rows={4}
                      onChange={(e) => setEndorsement(e.target.value)}
                      inputProps={{ maxLength: 1000 }}
                    />
                  </Grid>
                  <Grid item>
                    <Box style={{ textAlign: "right", marginTop: "5px" }}>
                      Character Count: {endorsement.length}/{maxCharacters}
                    </Box>
                  </Grid>
                  <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box sx={{ width: "300px" }}>
                      <Button
                        className={classes.button}
                        onClick={submitEndorsement}
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                      >
                        Submit Endorsement
                      </Button>
                    </Box>
                  </Grid>
                  <Grid container direction="column" alignItems="center">
                    <Box sx={{ maxWidth: "550px" }}>
                      <Box>
                        {errors && (
                          <ErrorSection errors={errors}></ErrorSection>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default Endorsement;
