import { makeStyles } from "@mui/styles";

const bgGray = "#e9e9e9";
const genicsPurple = "#0d0221";
const genicsPurpleFade = "#0d02215b";
const genicsPurpleLowOpacity = "#0d022111";
const genicsDarkBlue = "#0f084b";
const genicsLightBlue = "#a6cfd5";
const genicsGreen = "#c2e7d9";
const genicsGreenLowOpacity = "#c2e7d97c";
const sideNavWidth = 220;
const smallIconColor = "#0d0221";

const useStyles = makeStyles((theme) => ({

  notFoundContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    textAlign: "center",
  },
  notFoundTitle: {
    fontSize: "6rem",
  },
  notFoundMessage: {
    fontSize: "1.5rem",
    marginBottom: "20px",
  },
  notFoundLink: {
    fontSize: "1.2rem",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },

  bgGray: "#e9e9e9",
  genicsPurple: "#0d0221",
  genicsPurpleFade: "#0d02215b",
  genicsPurpleLowOpacity: "#0d022111",
  genicsDarkBlue: "#0f084b",
  genicsPurple: "#26408b",
  genicsLightBlue: "#a6cfd5",
  genicsGreen: "#c2e7d9",
  genicsGreenLowOpacity: "#c2e7d97c",
  genicsCrismond: "#dc143c",

  /* Top Level App */
  App: {
    display: "flex",
    height: "100vh",
    minHeight: "935px",
    flexDirection: "column",
    textAlign: "center",
    background: "white",
  },

  transparentBackground: {
    backgroundColor: "transparent",
    padding: "20px",
  },

  profileSection: {
    textAlign: "left",
    marginBottom: "30px",
    position: "relative",
  },

  profileAvatar: {
    width: "185px",
    height: "185px",
    borderRadius: "50%",
    objectFit: "cover",
    border: "3px solid white",
    boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
  },

  editAvatarButton: {
    position: "absolute",
    bottom: "10px",
    right: "0px",
    backgroundColor: "#333",
    color: "#fff",
    padding: "5px",
    border: "none",
    borderRadius: "50%",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#000",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  profileName: {
    fontSize: "38px",
    fontWeight: "bold",
    marginBottom: "-20px",
    color: genicsPurple,
  },

  profileAvatarContainer: {
    position: "relative",
    display: "inline-block",
  },

  profileLastName: {
    display: "inline",
    marginLeft: "10px"
  },

  profileFirstName: {
    display: "inline",
  },

  '@media (max-width: 600px)': {
    profileLastName: {
      display: "none", /* Hide last name on smaller screens */
    },
  },

  profileTitle: {
    fontSize: "16px",
    color: genicsPurpleFade,
    marginBottom: "20px",
  },

  editProfileButton: {
    backgroundColor: "#333",
    color: "#fff",
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontWeight: "bold",
    textTransform: "uppercase",
    width: "90%",

    "&:hover": {
      backgroundColor: "#000",
    },
  },
  
  PageLayout: {
    display: "flex",
    flexDirection: "row",
  },

  /* Main Page Content Styles */
  ContentBox: {
    flexGrow: 1,
  },

  Content: {
    margin: 10,
  },

  /* Main flex area contains Banner and content below */
  flexBanner: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexdDirection: "column",
  },

  /* Flex are containing the SideNav to the left and content to the right*/
  flexSideNav: {
    width: "100%",
    height: "100%",
    display: "flex",
  },

  /* Flex Area containing the main page content and footer below */
  content: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },

  landingPageFlexArea: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginTop: "20vh",
  },

  NavOpenWidth: {
    width: 220,
  },

  NavClosedWidth: {
    width: 0,
  },

  /*Navigation styles */
  NavButtons: {
    textTransform: "uppercase",
    width: "200px",
    marginTop: "15px",
    marginBottom: "15px",
    marginLeft: "10px",
    marginRight: "10px",
    padding: "10px",
    paddingleft: "20px",
    fontSize: "18px",
    fontWeight: "bold",
    textAlign: "Left",
    background: "white",

    "&:hover": {
      background: genicsDarkBlue,
      color: "#ffffff",
    },
  },

  /* Form Styles */
  FormCaption: {
    paddingBottom: "30px",
    paddingTop: "30px",
  },

  InputFormFlex: {
    paddingTop: "80px",
    display: "flex",
    justifyContent: "space-around",
  },

  Checkbox: {
    justifyContent: "left",
    justifyContent: "space-around",
  },

  /* User Verification styles */
  CentralizeDiv: {
    display: "inline",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },

  /* Dashboard Styles */
  DashbordTitle: {
    paddingBottom: "30px",
    paddingTop: "30px",
  },

  TableTitle: {
    textAlign: "left",
    paddingTop: 50,
    paddingBottom: 20,
  },
}));

export default useStyles;
