export const links = [
  // TODO PMG hiding this for now b/c /dashboard is very much in flux
  // {
  //   id: 1,
  //   url: "/dashboard",
  //   text: "home",
  //   roles: ["ROLE_USER", "ROLE_ADMIN", "ROLE_OWNER"],
  // },
  // TODO PMG - legacy menu option
  // {
  //   id: 2,
  //   url: "/organizations",
  //   text: "organizations",
  //   roles: ["ROLE_USER", "ROLE_ADMIN", "ROLE_OWNER"],
  // },
  {
    id: 3,
    url: "/admins",
    text: "admins",
    roles: ["ROLE_ADMIN", "ROLE_OWNER"],
  },
  {
    id: 4,
    url: "/users",
    text: "users",
    roles: ["ROLE_ADMIN", "ROLE_OWNER"],
  },
  // TODO PMG - legacy menu options
  // {
  //   id: 5,
  //   url: "/badges",
  //   text: "badges",
  //   roles: ["ROLE_OWNER"],
  // },
  // {
  //   id: 6,
  //   url: "/org-badges",
  //   text: "org-badges",
  //   roles: ["ROLE_ADMIN", "ROLE_USER"],
  // },
  // {
  //   id: 7,
  //   url: "/mybadges",
  //   text: "mybadges",
  //   roles: ["ROLE_USER"],
  // },
  {
    id: 8,
    url: "/skill-manager",
    text: "skills",
    roles: ["ROLE_OWNER"],
  },
  {
    id: 9,
    url: "/user-skill-manager",
    text: "skills",
    roles: ["ROLE_USER"],
  },
];
